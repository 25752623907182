/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import SimpleSelect, { components, ControlProps, InputActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { useDeepEqualMemo } from '../../../../hooks';
import colors from '../../../../theme/colors';
import { defaultFontValues, smallFontValues } from '../../../../theme/typography';
import { borderRadius, InputSize, sizes } from '../../../../theme/variables';
import { shallowEqual } from '../../../../utils/array';
import { except } from '../../../../utils/object';
import { ArrowUpIcon, CloseIcon, TickIcon } from '../../icons';
import Text from '../../Text';
import { focusHelper } from '../Base/Input.style';

const emptyArray = [] as any[];

export interface SelectProps {
  options: readonly Record<string, any>[];
  labelName?: string;
  valueName?: string;
  ordered?: boolean;
  name?: string;
  onChange: (args: any) => void;
  onBlur?: (args: any) => void;
  showResetButton?: boolean;
  small?: boolean;
  transparent?: boolean;
  createOptionFromSearch?: boolean;
  className?: string;
  disabled?: boolean;
  value: any;
  placeholder?: string | React.ReactNode;
  inputId?: string;
  hasError?: boolean;
  formatOptionLabel?: (args: Record<string, string | undefined>) => JSX.Element;
  menuOptions?: JSX.Element;
  isLoading?: boolean;
  hideSelectedOptions?: boolean;
  controlTestId?: string;
  inline?: boolean;
  inputSize?: InputSize;
  withValueRemove?: boolean;
  isOptionDisabled?: (option: Record<string, string | undefined>) => boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  fsMask?: boolean;
  customCss?: SerializedStyles;
  isPrefix?: boolean;
  customComponents?: Record<string, React.ReactNode>;
  hideDropDownIndicator?: boolean;
  menuMinWidth?: string;
  customStyles?: Record<string, any>;
}

interface IOption {
  label: string;
  value: string;
}

export const Control = ({ children, ...rest }: ControlProps<any, any, any>) => {
  const isInline = (rest.selectProps as any)['inline'];
  const isMasked = (rest.selectProps as any)['fsMask'];

  return (
    <components.Control
      {...rest}
      innerProps={{
        ...rest.innerProps,
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/28960
        'data-testid': rest.selectProps.controlTestId,
        className: isMasked ? 'fs-mask' : ''
      }}
    >
      {children}
      {!isInline && (
        <div
          css={[
            focusHelper,
            css`
              border: 2px solid ${rest.isFocused ? colors.azure50 : 'transparent'};
            `
          ]}
          className="input-focus-helper"
        />
      )}
    </components.Control>
  );
};

export const ClearIndicator = (props: any) =>
  components.ClearIndicator({
    ...props,
    innerProps: { ...props.innerProps, 'data-testid': 'clear-indicator' },
    children: (
      <CloseIcon
        css={css`
          z-index: 65;
        `}
      />
    )
  });

export const Input = (props: any) =>
  components.Input({
    ...props,
    'aria-invalid': props.selectProps['aria-invalid'],
    'aria-errormessage': props.selectProps['aria-errormessage']
  });

export const IndicatorSeparator = () => <></>;

export const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ArrowUpIcon
      className="select-dropdown-indicator"
      color={colors.black}
      css={css`
        transform: rotate(${props.selectProps.menuIsOpen ? 0 : 180}deg);
        transition:
          transform 0.3s ease,
          visibility 0.2s ease,
          opacity 0.2s ease;
      `}
    />
  </components.DropdownIndicator>
);

const Option = ({ children, data: option, ...rest }: any) => {
  const isMasked = (rest.selectProps as any)['fsMask'];
  return (
    <components.Option {...rest} className={isMasked ? 'fs-mask' : ''}>
      <div>
        {children}
        {option.description && (
          <div>
            <Text color={rest.isSelected ? colors.white : colors.grey60} type="tiny">
              {option.description}
            </Text>
          </div>
        )}
      </div>
      {rest.isSelected && <TickIcon color={colors.white} />}
    </components.Option>
  );
};

export const Menu = (props: any) => {
  const innerProps = { ...props.innerProps, 'data-testid': 'react-select-menu' };
  const isMasked = (props.selectProps as any)['fsMask'];

  return (
    <components.Menu {...props} innerProps={innerProps} className={isMasked ? 'fs-mask' : ''}>
      {props.children}
      {props.selectProps.menuOptions}
    </components.Menu>
  );
};

const SingleValue = (props: any) => {
  const isMasked = (props.selectProps as any)['fsMask'];
  const innerProps = {
    ...props.innerProps,
    'data-testid': 'select-single-value',
    className: isMasked ? 'fs-mask' : ''
  };
  return <components.SingleValue {...props} innerProps={innerProps} />;
};

export const inlineSelectStyles = (state: any) => {
  const hasError = state.selectProps.hasError;
  const isFocused = state.isFocused;

  let borderColor;
  if (hasError) {
    borderColor = colors.statusRed;
  } else if (isFocused) {
    borderColor = colors.azure50;
  } else {
    borderColor = 'transparent';
  }

  return {
    transition: 'border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease',
    border: `1px solid ${borderColor}`,
    backgroundColor: state.selectProps.isDisabled ? colors.grey10 : 'transparent',
    '&:hover': !isFocused
      ? {
          backgroundColor: colors.grey5,
          border: `1px solid ${hasError ? colors.statusRed : colors.grey5}`
        }
      : {}
  };
};

export const fontValues = (inputSize: InputSize) => {
  switch (inputSize) {
    case InputSize.Small:
      return { fontFamily: defaultFontValues.fontFamily, fontSize: smallFontValues.fontSize };
    case InputSize.Medium:
    case InputSize.Large:
    default:
      return { fontFamily: defaultFontValues.fontFamily, fontSize: defaultFontValues.fontSize };
  }
};

const optionColor = (props: any) => {
  if (props.isDisabled) {
    return colors.grey60;
  }

  return props.isSelected ? colors.white : colors.black;
};

export const styles = (
  inline: boolean,
  inputSize: InputSize,
  isPrefix?: boolean,
  hideDropDownIndicator?: boolean,
  menuMinWidth?: string
) => ({
  control: (_provided: any, state: any) => ({
    color: isPrefix ? colors.grey60 : colors.black,
    height: sizes.formsHeight,
    borderRadius: isPrefix ? '4px 0 0 4px' : `${borderRadius}px`,
    display: 'flex',
    justifyContent: 'space-between',
    ...fontValues(isPrefix ? InputSize.Small : inputSize),
    ...(inline
      ? inlineSelectStyles(state)
      : {
          backgroundColor: state.selectProps.isDisabled || isPrefix ? colors.grey10 : colors.white,
          border: isPrefix
            ? `2px solid ${colors.grey30}`
            : `1px solid ${state.selectProps.hasError ? colors.statusRed : colors.black}`,
          borderRight: isPrefix
            ? `5px solid ${colors.grey30}`
            : `1px solid ${state.selectProps.hasError ? colors.statusRed : colors.black}`
        })
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    color: isPrefix ? colors.grey60 : colors.black
  }),
  option: (base: any, props: any) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...fontValues(isPrefix ? InputSize.Small : inputSize),
    '&:first-of-type': {
      borderTopLeftRadius: `${borderRadius}px`,
      borderTopRightRadius: `${borderRadius}px`
    },
    '&:last-of-type': {
      borderBottomLeftRadius: `${borderRadius}px`,
      borderBottomRightRadius: `${borderRadius}px`
    },
    color: optionColor(props),
    cursor: props.isDisabled ? 'not-allowed' : ''
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: `${borderRadius + 2}px`,
    border: `1px solid ${colors.grey30}`,
    background: colors.white,
    zIndex: '999999',
    minWidth: menuMinWidth
  }),
  singleValue: (base: any) => ({
    ...base,
    color: isPrefix ? colors.grey60 : colors.black,
    fontValues: fontValues(isPrefix ? InputSize.Small : inputSize)
  }),
  placeholder: (base: any) => ({ ...base, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }),
  container: (base: any, state: any) => ({
    ...base,
    pointerEvents: 'auto',
    left: isPrefix ? '3px' : '0',
    cursor: state.selectProps.isDisabled ? 'not-allowed' : 'pointer',
    '.select-dropdown-indicator': hideDropDownIndicator
      ? {
          visibility: 'hidden',
          opacity: 0
        }
      : {},
    '&:hover': {
      '.select-dropdown-indicator': {
        visibility: 'visible',
        opacity: 1
      }
    }
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: isPrefix ? '8px 0' : '8px'
  })
});

const formatCreateLabel = (value: string) => (
  <span
    css={css`
      display: block;
      width: 100%;
      color: ${colors.azure50};
      &:hover {
        opacity: 0.8;
      }
    `}
  >
    {`Create "${value}"`}
  </span>
);

const Select = ({
  options = emptyArray,
  labelName = 'value',
  valueName = 'key',
  name = '',
  placeholder,
  disabled = false,
  ordered = false,
  className = '',
  createOptionFromSearch = false,
  showResetButton = false,
  onChange,
  value,
  inputSize = InputSize.Medium,
  isPrefix = false,
  customComponents = {},
  hideDropDownIndicator = true,
  menuMinWidth = '320px',
  ...props
}: SelectProps): any => {
  const [selectOptions, setSelectOptions] = React.useState<SelectProps['options']>(emptyArray);
  const [createdOption, setCreatedOption] = React.useState<IOption | null>(null);

  // memoize large options in parent
  const providedOptions = useDeepEqualMemo(options);

  React.useLayoutEffect(() => {
    let selectOptions = providedOptions.map(option => ({
      label: option[labelName],
      value: option[valueName],
      ...except(option, 'key', 'value')
    }));

    if (createdOption) {
      selectOptions = selectOptions.concat(createdOption);
    }

    if (ordered) {
      selectOptions.sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
    }

    setSelectOptions(selectOptions);
  }, [createdOption, providedOptions, labelName, valueName, ordered]);

  const onValueChange = (item: any) => {
    if (name) {
      onChange({ target: { name, value: item?.value } });
    } else {
      onChange(item?.value);
    }
  };

  const createFromSearch = (inputValue: string) => {
    setCreatedOption({ label: inputValue, value: inputValue });

    onValueChange({ value: inputValue });
  };

  const availableOptions = selectOptions.filter(
    (option: any) => typeof option.unavailable === 'undefined' || !option.unavailable
  );

  const pickedOption =
    selectOptions.find((option: any) => {
      if (Array.isArray(option.value)) {
        return shallowEqual(option.value, value);
      }

      return option.value === value;
    }) || null;

  const SelectComponent = (createOptionFromSearch ? CreatableSelect : SimpleSelect) as React.ElementType;

  (SelectComponent as any).displayName = 'SimpleSelect';

  return (
    <SelectComponent
      {...props}
      {...(createOptionFromSearch ? { onCreateOption: createFromSearch } : {})}
      styles={{
        ...styles(!!props.inline, inputSize, isPrefix, hideDropDownIndicator, menuMinWidth),
        ...props.customStyles
      }}
      css={props.customCss}
      placeholder={placeholder || (props.inline ? '—' : 'Please select an option')}
      value={pickedOption}
      onChange={onValueChange}
      options={availableOptions}
      className={className}
      components={{
        Control,
        ClearIndicator,
        IndicatorSeparator,
        DropdownIndicator,
        Option,
        Input,
        Menu,
        SingleValue,
        ...customComponents
      }}
      isClearable={showResetButton}
      isDisabled={disabled}
      formatCreateLabel={props.formatCreateLabel || formatCreateLabel}
      theme={(theme: any) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.azure50,
          primary25: colors.grey10
        }
      })}
    />
  );
};

export default Select;
