/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../../../../components/core/buttons/Button';
import FlexBox from '../../../../../../components/core/FlexBox';
import { OpenInNewIcon } from '../../../../../../components/core/icons';
import { useToggle } from '../../../../../../hooks';
import { IQuote } from '../../../../../../interfaces';
import { CarrierKey } from '../../../../../../interfaces/ICarrier';
import { QuoteKind, QuoteStatus } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';
// eslint-disable-next-line max-len
import ReportsModal from '../../../../../PostSalesExperience/_components/CarrierInstructions/UniversalCarrierConsent/ReportsModal';
import { getBridgingUnavailableMessage, isBridgingAvailable, isQuoteEligibleOrPartlyEligible } from '../../_helpers';

interface IBridge {
  quote: IQuote;
  compact?: boolean;
  onBridge: (quoteGid: string) => void;
}

const LinkView = ({ quote, compact }: { quote: IQuote; compact: boolean }) => {
  const isFinalizeAvailable =
    isQuoteEligibleOrPartlyEligible(quote) &&
    quote.kind === QuoteKind.RC2 &&
    quote.status === QuoteStatus.Success &&
    !compact;

  const allowBridge = isBridgingAvailable(quote);

  return (
    <FlexBox alignItemsCenter gap={spacings.px4}>
      {isFinalizeAvailable ? (
        <Button variant={ButtonVariant.Secondary} size={ButtonSize.Small} disabled={!allowBridge}>
          QuickBind
        </Button>
      ) : (
        <OpenInNewIcon
          css={css`
            cursor: ${allowBridge ? 'pointer' : 'not-allowed'};
          `}
          color={allowBridge ? colors.azure50 : colors.grey30}
        />
      )}
    </FlexBox>
  );
};

const Bridge = ({ quote, compact = false, onBridge }: IBridge) => {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const {
    gid,
    attributes: { binding_url, external_id, notice }
  } = quote;

  if (!binding_url && !external_id && !notice) {
    return null;
  }

  const allowBridge = isBridgingAvailable(quote);
  const bridgingUnavailableMessage = getBridgingUnavailableMessage(quote);

  if (notice || external_id) {
    return (
      <>
        <div
          onClick={() => (allowBridge ? onBridge(gid) : null)}
          data-tip={bridgingUnavailableMessage}
          data-for={`quote-${gid}-bridge-tooltip`}
          css={css`
            cursor: ${allowBridge ? 'pointer' : 'not-allowed'};
          `}
        >
          <LinkView quote={quote} compact={compact} />
        </div>
        <Tooltip id={`quote-${gid}-bridge-tooltip`} />
      </>
    );
  }

  if (binding_url) {
    return (
      <>
        <a
          href={allowBridge ? binding_url : ''}
          target="_blank"
          rel="noopener noreferrer"
          data-tip={bridgingUnavailableMessage}
          data-for={`quote-${gid}-bridge-tooltip`}
          onClick={e => {
            if (!allowBridge) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }

            if (quote.attributes.carrier.key === CarrierKey.Universal) {
              toggleExpanded();
              e.preventDefault();
            }
          }}
        >
          <LinkView quote={quote} compact={compact} />
        </a>
        <Tooltip id={`quote-${gid}-bridge-tooltip`} />
        {isExpanded && quote.attributes.carrier.key === CarrierKey.Universal && (
          <ReportsModal
            cancelHandler={() => toggleExpanded()}
            confirmText="Go to website"
            cancelText="Cancel"
            showCancelButton
            confirmHandler={() => {
              window.open(binding_url, '_blank', 'noopener,noreferrer');
              toggleExpanded();
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export default Bridge;
