import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { IProposal } from '../../../interfaces/IProposal';
import { useCreateProposal, useUpdateProposal } from '../../../queries/people/proposals/usePersonProposal';
import { reloadIframe } from '../_helpers';
import { useProposalContext } from './useProposalContext';

const useSyncProposal = ({
  currentProposal,
  personGid,
  leadGid
}: {
  currentProposal: IProposal | undefined;
  personGid: string;
  leadGid: string;
}) => {
  const { mutateAsync: createProposal } = useCreateProposal();
  const { mutateAsync: updateProposal } = useUpdateProposal();
  const queryClient = useQueryClient();

  const { currentProposalGid, setCurrentProposalGid, quotesForOpportunities } = useProposalContext();

  const currentProposalGidRef = React.useRef(currentProposalGid);

  currentProposalGidRef.current = currentProposalGid;

  // create proposal flow
  React.useEffect(() => {
    if (!currentProposalGidRef.current && Object.values(quotesForOpportunities).filter(Boolean).length > 0) {
      createProposal({
        personGid,
        quoteGids: Object.values(quotesForOpportunities).filter(Boolean),
        engagementGid: leadGid
      }).then(({ proposal }) => {
        setCurrentProposalGid(proposal.gid);
      });
    }
  }, [quotesForOpportunities, setCurrentProposalGid, personGid, leadGid, createProposal, queryClient]);

  // update proposal flow
  React.useEffect(() => {
    if (
      currentProposal &&
      !currentProposal.sent_at &&
      Object.values(quotesForOpportunities).filter(Boolean).length > 0
    ) {
      // if proposal has same quoteGids, do not update
      const proposalQuoteGids = (currentProposal.proposal_options?.[0]?.quotes.map(quote => quote.gid) || [])
        .sort()
        .join(',');
      const newQuoteGids = Object.values(quotesForOpportunities).filter(Boolean).sort().join(',');

      if (proposalQuoteGids === newQuoteGids || currentProposal.gid !== currentProposalGid) {
        return;
      }

      updateProposal({
        personGid,
        quoteGids: Object.values(quotesForOpportunities).filter(Boolean),
        proposalOptionGid: currentProposal.proposal_options?.[0]?.gid || '',
        proposalGid: currentProposal.gid
      }).then(() => {
        reloadIframe();
      });
    }
  }, [quotesForOpportunities, personGid, leadGid, updateProposal, queryClient, currentProposal, currentProposalGid]);
};

export default useSyncProposal;
