import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ITask } from '../../../interfaces';
import api from '../../../services/api';
import { TASK_REMINDERS_QUERY_KEY } from '../../tasks/useTaskReminders';
import { PERSON_TASKS_QUERY_KEY } from './usePersonTasks';

export interface TaskPayload {
  description: string;
  agent_id: string | number;
  kind: string;
  completed: boolean;
  due_date: string;
  due_time: string;
  priority: string;
  policy_id?: number | null;
  lead_id?: number;
}

const createTask = async ({ personGid, data }: { personGid: string; data: TaskPayload }): Promise<{ task: ITask }> =>
  await api.post(`/api/frontend/people/${personGid}/tasks`, {
    body: data
  });

const useCreateTask = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createTask,
    onSuccess: () => {
      onSuccess && onSuccess();
      queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] });
    }
  });
};

export default useCreateTask;
