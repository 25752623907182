/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import { FormikValues } from 'formik';
import moment from 'moment';
import * as React from 'react';
import * as yup from 'yup';

import FormModal from '../../../components/core/FormModal';
import {
  CheckboxField,
  DateInputField,
  SelectField,
  TextareaField,
  TimeField
} from '../../../components/core/forms/fields';
import { ModalSize } from '../../../components/core/Modal';
import PersonNavLink from '../../../components/PersonNavLink';
import { Translations } from '../../../constants';
import { IPerson, ITask, IUser } from '../../../interfaces';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import usePerson from '../../../queries/people/usePerson';
import authInfo from '../../../services/authInfo';
import { sizes } from '../../../theme/variables';
import { requiredField } from '../../../utils/yupRules';
import { globalTaskEditorStyle } from './GlobalTaskEditorStyle';

const BLANK = '';
const DEFAULT_PRIORITY = 'normal';

interface ITaskEditor {
  assignees: IUser[];
  cancelBtnHandler: () => void;
  confirmBtnHandler: (values: FormikValues) => void;
  person?: IPerson;
  leadId?: number;
  open?: boolean;
  task?: ITask | null;
}

export const getAvailableAssignees = (assignees: IUser[], currentAssignee: IUser | undefined) => {
  if (!currentAssignee || assignees.some(assignee => assignee.id === currentAssignee.id)) {
    return assignees;
  }
  return [...assignees, ...[currentAssignee]];
};

const TaskEditor = ({
  open = false,
  task,
  assignees,
  cancelBtnHandler,
  confirmBtnHandler,
  person,
  leadId
}: ITaskEditor): JSX.Element => {
  const { data: leadDispositions, isFetching } = useLeadDispositions(leadId);
  const { data: fetchedPerson } = usePerson(task?.person_gid);

  const taskPerson = fetchedPerson || person;

  return (
    <>
      <Global styles={globalTaskEditorStyle} />
      {taskPerson && open && (
        <FormModal
          size={ModalSize.large}
          confirmHandler={(values: FormikValues) => confirmBtnHandler(values)}
          cancelHandler={cancelBtnHandler}
          customHeader={<PersonNavLink person={taskPerson} linkPrefix="Task details." />}
          confirmText="Submit"
          initialValues={{
            id: task?.id,
            agent_id: task?.agent_id || leadDispositions?.current_disposition?.assignee?.id || authInfo.currentUserId,
            description: task?.description || BLANK,
            completed: task?.completed || false,
            kind: task?.kind || BLANK,
            due_date: task?.due_date || BLANK,
            due_time: task?.due_time || BLANK,
            priority: task?.priority || DEFAULT_PRIORITY
          }}
          validationSchema={yup.object().shape({
            description: requiredField,
            agent_id: requiredField,
            due_date: yup
              .date()
              .required('This field is required')
              .min(moment().subtract('3', 'years').toDate(), "Date can't be earlier than 3 years ago")
              .max(moment().add('3', 'years').toDate(), "Date can't be later than 3 years from today"),
            kind: requiredField
          })}
          renderForm={() => {
            return (
              <div
                css={css`
                  margin: 8px 36px 0 36px;
                `}
              >
                <div
                  css={css`
                    display: grid;
                    max-width: calc(2 * ${sizes.mediumFormInputWidth}px + 2 * 24px);
                    grid-template-columns: repeat(auto-fit, ${sizes.mediumFormInputWidth}px);
                    gap: 24px;
                  `}
                >
                  <SelectField
                    options={Translations.taskKindOptions}
                    name="kind"
                    label="Task type"
                    required
                    id="task-type"
                  />
                  <DateInputField
                    testId="task-date"
                    name="due_date"
                    label="Date"
                    required
                    id="task-date"
                    maxDate={moment().add('3', 'years').toDate()}
                    minDate={moment().subtract('3', 'years').toDate()}
                  />
                </div>
                <div
                  css={css`
                    display: grid;
                    max-width: calc(2 * ${sizes.mediumFormInputWidth}px + 2 * 24px);
                    grid-template-columns: repeat(auto-fit, ${sizes.mediumFormInputWidth}px);
                    gap: 24px;
                  `}
                >
                  <SelectField
                    name="agent_id"
                    label="Agent"
                    options={getAvailableAssignees(assignees, task?.assignee)}
                    id="task-agent-id"
                    disabled={isFetching}
                    labelName="name"
                    valueName="id"
                  />
                  <TimeField name="due_time" label="Time" id="task-time" />
                </div>
                <div
                  css={css`
                    display: grid;
                    max-width: calc(2 * ${sizes.mediumFormInputWidth}px + 2 * 24px);
                    grid-template-columns: repeat(auto-fit, ${sizes.mediumFormInputWidth}px);
                    gap: 24px;
                  `}
                >
                  <SelectField
                    label="Priority"
                    options={Translations.taskPriorityOptions}
                    id="task-priority"
                    name="priority"
                  />
                </div>
                <div
                  css={css`
                    max-width: calc(2 * ${sizes.mediumFormInputWidth}px + 24px);
                  `}
                >
                  <TextareaField label="Description" required name="description" id="task-description" />
                  <CheckboxField name="completed" id="task-completed-checkbox" label="Completed" />
                </div>
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default TaskEditor;
