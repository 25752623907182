/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { FieldProps } from 'formik';
import { ReactElement, useEffect, useMemo } from 'react';

import info from '../../../assets/new_icons/info.svg';
import { ICarrier } from '../../../interfaces/ICarrier';
import { FileToUpload } from '../../../interfaces/IDocument';
import useDocumentRulesQuery, {
  DocumentRule,
  DocumentRuleFilter,
  RuleDemands
} from '../../../queries/document_rules/useDocumentRules';
import authInfo from '../../../services/authInfo';
import Loader from '../../common/Loader';
import Tooltip from '../../common/Tooltip/NewTooltip';
import Header from '../../common/typography/Header';
import { FilesList, UploadArea } from '../../common/UploadFiles';
import RuleRow from './RuleRow';

export interface DocumentFile {
  document_type_id: number;
  document_type_key: string;
  document_type_title: string;
  uid?: string;
  name: string;
  id?: number;
  deleted?: boolean;
}

const normalizeFiles = (files: DocumentFile[]): DocumentFile[] =>
  files.filter(file => !file.deleted).map(file => (!file.uid ? { ...file, uid: crypto.randomUUID() } : file));

const TOOLTIP_MESSAGE =
  'Some cases require additional documents. Normally you can see them on the last page of the declaration page.';

const emptyArray = [] as DocumentRule[];

const useRuleFilter = ({ carrier_id, policy_type, state }: DocumentRuleFilter) =>
  useMemo(() => ({ carrier_id, policy_type, state }), [carrier_id, policy_type, state]);

const UploadFiles: React.FC<FieldProps & { partner?: string; carrier?: ICarrier }> = ({
  field: { name, value, onChange },
  form: { setFieldValue, initialValues, values },
  partner,
  carrier
}): ReactElement => {
  const currentFilter = useRuleFilter(values);
  const initialFilter = useRuleFilter(initialValues);
  const filterIsChanged = JSON.stringify(currentFilter) !== JSON.stringify(initialFilter);
  const files = useMemo(() => normalizeFiles(value), [value]);
  const couldNotCollectAvailable = authInfo.features.set_policy_documents_could_not_collect;

  const onAttachHandler = (files: FileToUpload[]) =>
    onChange({ target: { name, value: [...files, ...(value || [])] } });
  const onDeleteHandler = (fileToDelete: DocumentFile) => {
    const uploadedFiles = (value || []) as DocumentFile[];
    const updatedValue = uploadedFiles.map(file =>
      (!!fileToDelete.uid && file.uid === fileToDelete.uid) || (!!fileToDelete.id && file.id === fileToDelete.id)
        ? { ...fileToDelete, deleted: true }
        : file
    );

    onChange({ target: { name, value: updatedValue } });
  };

  const queryClient = useQueryClient();
  const { data = emptyArray, isLoading } = useDocumentRulesQuery(
    { ...currentFilter, partner },
    filterIsChanged ? '' : initialValues.id
  );

  const onChangeHandler = (rule: DocumentRule) => {
    queryClient.setQueryData(
      ['documentRules', { filter: { ...currentFilter, partner }, policyId: filterIsChanged ? '' : initialValues.id }],
      (oldData: any) => {
        const key = Object.keys(oldData)[0]!;
        const copy = [...(oldData[key] as DocumentRule[])];
        const ruleIndex = copy.findIndex(oldRule => oldRule.document_rule_id === rule.document_rule_id);
        copy[ruleIndex] = rule;

        return { [key]: copy };
      }
    );
  };

  useEffect(() => {
    const submittedData = data.map(rule => ({
      document_rule_id: rule.document_rule_id,
      demand: rule.demand,
      responsible: rule.responsible,
      could_not_collect: rule.could_not_collect || false
    }));

    setFieldValue('document_rules_policies_attributes', submittedData);
  }, [setFieldValue, data]);

  useEffect(() => {
    if (data[0] && couldNotCollectAvailable) {
      queryClient.setQueryData(
        ['documentRules', { filter: { ...currentFilter, partner }, policyId: filterIsChanged ? '' : initialValues.id }],
        (oldData: any) => {
          const key = Object.keys(oldData)[0]!;
          const copy = (oldData[key] as DocumentRule[]).map(rule => ({
            ...rule,
            could_not_collect:
              !files.find((file: DocumentFile) => file.document_type_id === rule.document_type_id) &&
              rule.could_not_collect
          }));

          return { [key]: copy };
        }
      );
    }
  }, [data, queryClient, files, currentFilter, filterIsChanged, initialValues.id, couldNotCollectAvailable, partner]);

  const createDocRows = (demand: RuleDemands, options: Record<string, boolean> = {}) =>
    data
      .filter(rule => rule.demand === demand)
      .map(rule => (
        <RuleRow
          {...options}
          key={rule.document_rule_id}
          rule={rule}
          files={files.filter(file => file.document_type_id === rule.document_type_id)}
          onDeleteHandler={onDeleteHandler}
          onChangeHandler={onChangeHandler}
        />
      ));

  const requiredDocs = createDocRows(RuleDemands.Required);
  const optionalDocs = createDocRows(RuleDemands.Optional, { optional: true });
  const otherDocs = createDocRows(RuleDemands.Unavailable, { other: true });

  const policyWithRulesFlow = isLoading ? (
    <div
      css={css`
        display: flex;
        justify-content: center;
        padding: 1rem;
      `}
    >
      <Loader height="40px" width="40px" />
    </div>
  ) : (
    <>
      <Header className="mt-4">Policy documents</Header>
      {!data[0] ? (
        <p>Required documents will be shown here when you select a carrier and a state.</p>
      ) : (
        <>
          {currentFilter.carrier_id && (
            <p>This List of documents is required for {carrier?.name || '—'}. You can upload later.</p>
          )}
        </>
      )}
      {requiredDocs}
      {optionalDocs[0] && (
        <div
          css={css`
            margin: 28px 0 12px 0;
          `}
        >
          <span
            css={css`
              margin-right: 12px;
            `}
          >
            Select docs required for this policy if applicable.
          </span>
          <img src={info} alt="Info" data-tip={TOOLTIP_MESSAGE} data-for="tooltip-text" />
          <Tooltip id="tooltip-text" />
        </div>
      )}
      {optionalDocs}
      {otherDocs[0] && (
        <div
          css={css`
            margin: 28px 0 12px 0;
          `}
        >
          <span
            css={css`
              margin-right: 12px;
            `}
          >
            Other docs
          </span>
        </div>
      )}
      {otherDocs}
    </>
  );

  return (
    <>
      <UploadArea
        onAttach={files => {
          onAttachHandler(files);

          return Promise.resolve();
        }}
        isForPolicy
      />
      {!isLoading && !data[0] && initialValues.id ? (
        <FilesList files={normalizeFiles(value) as any} filter={file => !file.deleted} onDelete={onDeleteHandler} />
      ) : (
        policyWithRulesFlow
      )}
    </>
  );
};

export default UploadFiles;
