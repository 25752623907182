/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Container from '../../../components/core/Container';
import CopyableAddress from '../../../components/core/CopyableAddress';
import FlexBox from '../../../components/core/FlexBox';
import { CarIcon, ChevronLeft, HomeIcon, MotorbikeIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import VehicleKind from '../../../components/core/VehicleKind';
import CopyableAnswer from '../../../components/UIFlow/CopyableAnswer';
import { useToggle } from '../../../hooks';
import { IPerson } from '../../../interfaces';
import { buildVehicleTitle, notMotorcycleOrTrailer } from '../../../interfaces/IVehicle';
import usePersonVehicles from '../../../queries/people/personVehicles';
import usePersonHomes from '../../../queries/people/usePersonHomes';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import useVerticalTabsCallback from '../_hooks/useVerticalTabsCallback';

const AssetsSummary = ({ person }: { person: IPerson }) => {
  const personGid = person.gid;

  const { data: vehicles } = usePersonVehicles(personGid);
  const { data: homes } = usePersonHomes(personGid);
  const { setSelectedIndex, onTabClose, selectedIndex } = useVerticalTabsCallback();
  const autos = vehicles?.filter(v => notMotorcycleOrTrailer(v.kind));
  const nonAutoVehicles = vehicles?.filter(v => !notMotorcycleOrTrailer(v.kind));
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <FlexBox
      border
      backgroundColor={colors.white}
      roundBorder
      columnDirection
      p={spacings.px16}
      css={css`
        width: 440px;
      `}
      onClick={() => {
        toggleOpen();
        if (isOpen) {
          onTabClose();
        } else {
          setSelectedIndex(1);
        }
      }}
    >
      <FlexBox justifySpaceBetween>
        <Paragraph type="large" bold mb={spacings.px8}>
          Assets
        </Paragraph>
        <ChevronLeft
          css={css`
            transform: rotate(${isOpen || selectedIndex === 1 ? '180deg' : '0deg'});
            transition: transform 0.2s;
          `}
          color={colors.black}
        />
      </FlexBox>

      {(homes || []).map(home => {
        return (
          <FlexBox key={home.gid} columnDirection gap={spacings.px8}>
            <FlexBox key={home.gid} gap={spacings.px4}>
              <HomeIcon />
              <CopyableAddress address={home.address} />
            </FlexBox>
            <FlexBox ml={spacings.px28} gap={spacings.px8}>
              <FlexBox gap={spacings.px4}>
                <Text type="small" color={colors.grey60}>
                  Built in
                </Text>
                <Text type="small">{home.year_built}</Text>
              </FlexBox>
              <Container border />
              <FlexBox gap={spacings.px4}>
                <Text type="small" color={colors.grey60}>
                  Sqft
                </Text>
                <Text type="small">{home.square_feet}</Text>
              </FlexBox>
              <Container border />
              <FlexBox gap={spacings.px4}>
                <Text type="small" color={colors.grey60}>
                  Roof updt
                </Text>
                <Text type="small">{home.roof_year}</Text>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        );
      })}
      <Container border mt={spacings.px8} />

      {(autos || []).map(vehicle => (
        <FlexBox columnDirection gap={spacings.px8} key={vehicle.gid} pv={spacings.px8}>
          <FlexBox alignItemsCenter gap={spacings.px8}>
            <CarIcon />
            <Paragraph>{buildVehicleTitle(vehicle)}</Paragraph>
          </FlexBox>

          {vehicle.vin && (
            <FlexBox ml={spacings.px28}>
              <Text type="small" color={colors.grey60}>
                VIN:
              </Text>
              <CopyableAnswer fsMask textType="small" value={vehicle.vin} />
            </FlexBox>
          )}
        </FlexBox>
      ))}
      {(nonAutoVehicles || []).map(vehicle => (
        <FlexBox columnDirection gap={spacings.px8} key={vehicle.gid}>
          <FlexBox justifySpaceBetween alignItemsCenter>
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <MotorbikeIcon />
              <Paragraph>{buildVehicleTitle(vehicle)}</Paragraph>
              <VehicleKind kind={vehicle.kind} />
            </FlexBox>
          </FlexBox>
          {vehicle.vin && (
            <FlexBox ml={spacings.px28}>
              <Text type="small" color={colors.grey60}>
                VIN:
              </Text>
              <CopyableAnswer fsMask textType="small" value={vehicle.vin} />
            </FlexBox>
          )}
        </FlexBox>
      ))}
    </FlexBox>
  );
};

export default AssetsSummary;
