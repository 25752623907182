/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import FlexBox from '../../components/core/FlexBox';
import usePerson from '../../queries/people/usePerson';
import CustomerViewHeader from './CustomerViewHeader';
import Summary from './Summary';
import Tabs from './Tabs';

const CustomerView = () => {
  const { gid: personGid } = useParams();
  const { data: person } = usePerson(personGid);

  if (!personGid || !person) {
    return null;
  }

  return (
    <div
      css={css`
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr auto;
        grid-template-areas: 'customer tabs';
        height: 100%;
      `}
    >
      <div
        css={css`
          grid-area: customer;
          overflow: auto;
        `}
      >
        <div
          css={css`
            position: fixed;
            width: 100%;
          `}
        >
          <CustomerViewHeader />
        </div>

        <Summary person={person} />
      </div>

      <FlexBox
        css={css`
          overflow: hidden;
          grid-area: tabs;
        `}
      >
        <Tabs person={person} />
      </FlexBox>
    </div>
  );
};

export default CustomerView;
