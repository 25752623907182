import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ChatIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import QuestionAnswerPair from '../../../../components/UIFlow/QuestionAnswerPair';
import { ILead } from '../../../../interfaces';
import { useLeadFeedbacks } from '../../../../queries/leads/feedback/useLeadFeedback';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const LeadFeedbacks = ({ lead }: { lead: ILead }) => {
  const { data: feedbacks, isSuccess } = useLeadFeedbacks({ leadGid: lead.gid });

  if (!isSuccess) {
    return null;
  }

  const lastFeedback = feedbacks[0];

  return (
    <>
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <ChatIcon />
        <Paragraph type="small" bold>
          Feedback on ISR
        </Paragraph>
      </FlexBox>
      <Container ml={spacings.px32} mt={spacings.px8}>
        <FlexBox gap={spacings.px8} columnDirection>
          <QuestionAnswerPair
            questionLabel={
              <Text color={colors.grey60} type="small">
                Evaluation
              </Text>
            }
            answerLabel={
              <Text color={colors.grey60} type="small">
                {lastFeedback?.evaluation ? `${lastFeedback.evaluation}/5` : 'Not provided'}
              </Text>
            }
          />

          <QuestionAnswerPair
            questionLabel={
              <Text color={colors.grey60} type="small">
                Feedback
              </Text>
            }
            answerLabel={
              <>
                <Text
                  singleLine
                  color={colors.grey60}
                  type="small"
                  data-tip={lastFeedback?.text}
                  data-for="lead-feedback"
                >
                  {lastFeedback?.text || 'Not provided'}
                </Text>
                {lastFeedback?.text && <Tooltip id="lead-feedback" />}
              </>
            }
          />
        </FlexBox>
      </Container>
    </>
  );
};

export default LeadFeedbacks;
