/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { Field, FieldProps } from 'formik';

import { LabelSize } from '../../../../theme/variables';
import AsyncSelect, { AsyncSelectProps } from '../../inputs/AsyncSelect';
import FormError from './FormError';
import LabeledInput, { LabeledInputProps } from './LabeledInput';

interface AsyncSelectFieldProps extends Partial<AsyncSelectProps> {
  label: LabeledInputProps['label'];
  id: string;
  name: string;
  required?: boolean;
  inline?: boolean;
  secondary?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  errorNormalizer?: (args: any) => string;
  asyncAction: AsyncSelectProps['asyncAction'];
  optionsNormalizer: AsyncSelectProps['optionsNormalizer'];
}

const AsyncSelectField = ({
  label,
  id,
  name,
  required,
  inline,
  secondary = false,
  customLabelCss,
  labelSize,
  errorNormalizer,
  ...rest
}: AsyncSelectFieldProps): JSX.Element => {
  return (
    <LabeledInput
      id={id}
      required={required}
      label={label}
      inline={inline}
      secondary={secondary}
      disabled={rest.disabled}
      customCss={customLabelCss}
      labelSize={labelSize}
    >
      <Field name={name}>
        {({ field, meta, form }: FieldProps) => {
          const hasError = meta.error && meta.touched;

          return (
            <>
              <AsyncSelect
                {...field}
                {...rest}
                disabled={rest.disabled || form.status === 'disabled'}
                // @ts-expect-error - wrong type in react-select
                onBlur={() => form.setFieldTouched(name, true)}
                inputId={id}
                inline={inline}
                hasError={!!hasError}
                aria-invalid={!!hasError}
                aria-errormessage={`${id}-error`}
              />
              {inline && !!hasError && (
                <FormError
                  id={id}
                  hasError={!!hasError}
                  error={errorNormalizer ? errorNormalizer(meta.error) : meta.error}
                />
              )}
              {!inline && (
                <FormError
                  id={id}
                  hasError={!!hasError}
                  error={errorNormalizer ? errorNormalizer(meta.error) : meta.error}
                />
              )}
            </>
          );
        }}
      </Field>
    </LabeledInput>
  );
};

export default AsyncSelectField;
