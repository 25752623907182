/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import featureFlags from '../../../../../constants/featureFlags';
import { IOpportunity } from '../../../../../interfaces';
import { isAutoAsset } from '../../../../../interfaces/IPersonAsset';
import { notMotorcycleOrTrailer } from '../../../../../interfaces/IVehicle';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { useVehiclesWithVerificationStatus } from '../../../_hooks/useVehicleProfileAnswersWithVerificationAnswers';
import AdditionalAnswers from './AdditionaAnswers';
import NonAutoVehicle from './NonAutoVehicle';
import Vehicle from './Vehicle';

interface VehiclesProps {
  vehicleOpportunities: IOpportunity[];
  personGid: string;
}

const Vehicles = ({ vehicleOpportunities, personGid }: VehiclesProps) => {
  const vehicles = vehicleOpportunities
    .flatMap(({ assets }) => assets)
    .filter(Boolean)
    .filter(isAutoAsset);

  const answersWithVerificationStatus = useVehiclesWithVerificationStatus({
    personGid,
    vehicleGids: vehicles.map(({ gid }) => gid)
  });

  const autos = vehicles.filter(v => notMotorcycleOrTrailer(v.kind));
  const nonAutoVehicles = vehicles.filter(v => !notMotorcycleOrTrailer(v.kind));

  if (autos.length === 0 && nonAutoVehicles.length === 0) {
    return (
      <Paragraph
        mt={spacings.px24}
        color={colors.grey60}
        customCss={css`
          text-align: center;
        `}
      >
        No vehicles added
      </Paragraph>
    );
  }

  return (
    <FlexBox columnDirection gap={spacings.px12}>
      {autos.map((vehicle, index) => (
        <Vehicle
          key={vehicle.gid}
          vehicle={vehicle}
          index={index}
          answers={answersWithVerificationStatus[vehicle.gid]}
        />
      ))}
      {nonAutoVehicles.map((vehicle, index) => (
        <NonAutoVehicle
          key={vehicle.gid}
          vehicle={vehicle}
          index={autos.length + index}
          answers={answersWithVerificationStatus[vehicle.gid]}
        />
      ))}
      {featureFlags.vehicleAdditionalQuestions && autos.length ? <AdditionalAnswers personGid={personGid} /> : null}
    </FlexBox>
  );
};

export default Vehicles;
