/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import { FieldArray } from 'formik';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import * as yup from 'yup';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import AddButton from '../../../../components/core/buttons/AddButton';
import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../components/core/FlexBox';
import FormModal, { FormModalProps } from '../../../../components/core/FormModal';
import { CheckboxField, DateInputField, InputField, SelectField } from '../../../../components/core/forms/fields';
import { DeleteIcon } from '../../../../components/core/icons';
import { ModalSize } from '../../../../components/core/Modal';
import Paragraph from '../../../../components/core/Paragraph';
import { Translations } from '../../../../constants';
import useConstant from '../../../../hooks/useConstant';
import { IRelatedPerson, isPersonAgedToDrive } from '../../../../interfaces/IPerson';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { dateFormatter, humanize } from '../../../../utils/formatter';
import { requiredField, requiredMinMaxDOBField } from '../../../../utils/yupRules';

type ManualDriver = {
  first_name: string;
  middle_name: string | null;
  last_name: string;
  date_of_birth: string;
  kind: string;
  gid: string;
};

type Values = {
  relatedPersonDrivers: {
    [personGid: string]: boolean;
  };
  // Actually we are not omitting gid on submit, but consumer should not read that
  manualDrivers: Omit<ManualDriver, 'gid'>[];
};

interface Props {
  isLoading: FormModalProps<Values>['confirmationInProgress'];
  cancelHandler: FormModalProps<Values>['cancelHandler'];
  confirmHandler: (values: Values) => void;
  relatedPeople: IRelatedPerson[];
  addedDriversGids: string[];
}

const INCLUDED_REASON = 'Driver already added to the drivers list' as const;
const AGE_REASON = 'You cannot add a driver younger than 15 y.o' as const;

const AddDriversModal = ({ cancelHandler, confirmHandler, isLoading, relatedPeople, addedDriversGids }: Props) => {
  const todayDate = useConstant(() => new Date());
  const initialGeneratedGid = useConstant(() => crypto.randomUUID());

  const disabledInputReasons = relatedPeople.reduce(
    (acc, relatedPerson) => ({
      ...acc,
      [relatedPerson.gid]:
        (addedDriversGids.includes(relatedPerson.gid) && INCLUDED_REASON) ||
        (!isPersonAgedToDrive(relatedPerson) && AGE_REASON)
    }),
    {} as Record<string, typeof INCLUDED_REASON | typeof AGE_REASON | false>
  );

  const relatedPersonDriversInitialValues = relatedPeople.reduce(
    (acc, relatedPerson) => ({
      ...acc,
      [relatedPerson.gid]: addedDriversGids.includes(relatedPerson.gid)
    }),
    {}
  );

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <FormModal
      size={ModalSize.extra}
      confirmationInProgress={isLoading}
      initialValues={{
        manualDrivers:
          relatedPeople.length && !Object.values(disabledInputReasons).every(Boolean)
            ? []
            : [
                {
                  first_name: '',
                  middle_name: '',
                  last_name: '',
                  date_of_birth: '',
                  kind: '',
                  gid: initialGeneratedGid
                }
              ],
        relatedPersonDrivers: relatedPersonDriversInitialValues
      }}
      title="Add drivers"
      confirmText="Add"
      confirmHandler={confirmHandler}
      cancelHandler={cancelHandler}
      validationSchema={yup.object().shape({
        manualDrivers: yup.array().of(
          yup.object().shape({
            first_name: requiredField,
            last_name: requiredField,
            date_of_birth: requiredMinMaxDOBField(),
            kind: requiredField
          })
        )
      })}
      renderForm={({ values }) => {
        return (
          <FieldArray
            name="manualDrivers"
            render={driversArrayHelpers => (
              <>
                {relatedPeople.length ? (
                  <>
                    <Paragraph bold mb={spacings.px16}>
                      Select drivers from added people
                    </Paragraph>
                    <FlexBox
                      columnDirection
                      gap={spacings.px8}
                      justifySpaceBetween
                      pb={spacings.px32}
                      customCss={css`
                        border-bottom: solid 2px ${colors.grey10};
                      `}
                    >
                      {relatedPeople.map(relatedPerson => (
                        <React.Fragment key={relatedPerson.gid}>
                          <div
                            css={css`
                              max-width: fit-content;
                            `}
                          >
                            <CheckboxField
                              fsMask
                              labelProps={{
                                'data-tip': disabledInputReasons[relatedPerson.gid] || undefined,
                                'data-for': `person-tip-${relatedPerson.gid}`,
                                className: 'fs-mask'
                              }}
                              description={`DOB: ${dateFormatter(relatedPerson.date_of_birth) || '—'} | Relationship: ${
                                humanize(relatedPerson.kind) || '—'
                              }`}
                              label={relatedPerson.name}
                              id={`relatedPersonDrivers.${relatedPerson.gid}`}
                              name={`relatedPersonDrivers.${relatedPerson.gid}`}
                              disabled={!!disabledInputReasons[relatedPerson.gid]}
                            />
                          </div>
                          <Tooltip place="left" id={`person-tip-${relatedPerson.gid}`} />
                        </React.Fragment>
                      ))}
                    </FlexBox>
                  </>
                ) : null}
                <Grid
                  container
                  justifyContent="space-between"
                  css={
                    relatedPeople.length
                      ? css`
                          padding-top: ${spacings.px28}px;
                        `
                      : undefined
                  }
                >
                  <FlexBox gap={spacings.px12} alignItemsCenter>
                    <Paragraph bold type="large">
                      Enter manually
                    </Paragraph>
                    <AddButton
                      testId="add-drivers-modal-add-driver-item"
                      onClick={() =>
                        driversArrayHelpers.push({
                          first_name: '',
                          middle_name: '',
                          last_name: '',
                          date_of_birth: '',
                          kind: '',
                          gid: crypto.randomUUID()
                        })
                      }
                      content="Add driver"
                    />
                  </FlexBox>
                  {values.manualDrivers.map((driver: ManualDriver, index: number) => (
                    <Grid item container xs={12} columnSpacing={2} alignItems="center" key={driver.gid}>
                      <Grid item xs={3.33}>
                        <InputField
                          fsMask
                          label="First name"
                          id={`manualDrivers.${index}.first_name`}
                          name={`manualDrivers.${index}.first_name`}
                          required
                        />
                      </Grid>
                      <Grid item xs={3.33}>
                        <InputField
                          fsMask
                          label="Middle name"
                          id={`manualDrivers.${index}.middle_name`}
                          name={`manualDrivers.${index}.middle_name`}
                        />
                      </Grid>
                      <Grid item xs={3.33}>
                        <InputField
                          fsMask
                          label="Last name"
                          id={`manualDrivers.${index}.last_name`}
                          name={`manualDrivers.${index}.last_name`}
                          required
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <DateInputField
                          fsMask
                          label="DOB"
                          id={`manualDrivers.${index}.date_of_birth`}
                          name={`manualDrivers.${index}.date_of_birth`}
                          required
                          maxDate={todayDate}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <SelectField
                          required
                          label="Relationship to customer"
                          id={`manualDrivers.${index}.kind`}
                          name={`manualDrivers.${index}.kind`}
                          options={Translations.relationshipOptions}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <ButtonWithoutBorder
                          data-testid={`add-drivers-modal-delete-manual-driver-${index}`}
                          customCss={css`
                            padding: ${spacings.px8}px;
                            position: relative;
                            bottom: ${spacings.px8}px;
                            &:hover {
                              opacity: 0.8;
                            }
                          `}
                          onClick={() => {
                            driversArrayHelpers.remove(index);
                          }}
                        >
                          <DeleteIcon color={colors.grey60} />
                        </ButtonWithoutBorder>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          />
        );
      }}
    />
  );
};

export default AddDriversModal;
