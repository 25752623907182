/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import CopyableAddress from '../../../../components/core/CopyableAddress';
import FlexBox from '../../../../components/core/FlexBox';
import { MailIcon, UserAddIcon, UserIcon } from '../../../../components/core/icons';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../../components/core/QuestionVerificationStatusIcon';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import EmptyAnswer from '../../../../components/UIFlow/EmptyAnswer';
import QuestionAnswerPair from '../../../../components/UIFlow/QuestionAnswerPair';
import { Translations } from '../../../../constants';
import { IPerson, IRelatedPerson } from '../../../../interfaces';
import DatapointKey from '../../../../interfaces/IDatapoint';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import TabSkeleton from '../../_components/VerticalTabs/TabSkeleton';
import { useAnswersWithVerificationStatus } from '../../_hooks/useAnswersWithVerificationStatus';
import PersonInfo from './PersonInfo';
import PersonName from './PersonName';

interface ICustomerProfile {
  person: IPerson;
  relatedPeople: IRelatedPerson[];
}

const CustomerProfile = ({ person, relatedPeople }: ICustomerProfile) => {
  const answersByPersonGid = useAnswersWithVerificationStatus({
    personGid: person.gid,
    relatedPeopleGids: relatedPeople.map(({ gid }) => gid)
  });

  const personAnswers = answersByPersonGid[person.gid];

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!personAnswers) {
    return <TabSkeleton ph={spacings.px12} pb={spacings.px12} />;
  }

  return (
    <FlexBox columnDirection gap={spacings.px12}>
      <PersonInfo
        answers={personAnswers}
        person={person}
        testId="collect-info-tab-customer-profile-results"
        primaryCustomer={person}
        header={
          <FlexBox justifySpaceBetween>
            <FlexBox
              gap={spacings.px8}
              alignItemsCenter
              customCss={css`
                flex-wrap: wrap;
              `}
            >
              <UserIcon className="person-info-user-icon" width={spacings.px20} height={spacings.px20} />

              <PersonName person={person} />
              <Tag
                transparent
                textType="small"
                textColor={colors.azure50}
                backgroundColor={colors.azure50}
                border
                label="Primary customer"
                customCss={css`
                  padding: 0 ${spacings.px8}px;
                `}
              />
            </FlexBox>
          </FlexBox>
        }
      />

      {relatedPeople.map((relatedPerson, index) => {
        const personAnswers = answersByPersonGid[relatedPerson.gid];
        if (!personAnswers) {
          return <React.Fragment key={relatedPerson.gid} />;
        }
        return (
          <PersonInfo
            key={relatedPerson.gid}
            answers={personAnswers}
            person={relatedPerson}
            primaryCustomer={person}
            testId={`collect-info-tab-customer-profile-results-related-person-${index}`}
            header={
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <UserAddIcon width={spacings.px20} height={spacings.px20} />
                <PersonName person={relatedPerson} />
                <Tag
                  transparent
                  textType="small"
                  textColor={colors.azure50}
                  backgroundColor={colors.azure50}
                  border
                  label={Translations.relationKind(relatedPerson.kind)}
                  customCss={css`
                    padding: 0 ${spacings.px8}px;
                  `}
                />
              </FlexBox>
            }
          />
        );
      })}

      <FlexBox gap={spacings.px8} alignItemsCenter>
        <MailIcon width={spacings.px20} height={spacings.px20} />
        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              Mailing address
            </Text>
          }
          answerLabel={
            person.mailing_address ? (
              <>
                {personAnswers && personAnswers[DatapointKey.PersonMailingAddress]?.verificationStatus && (
                  <QuestionVerificationStatusIcon
                    verificationStatus={personAnswers[DatapointKey.PersonMailingAddress]?.verificationStatus}
                  />
                )}
                <CopyableAddress
                  address={person.mailing_address}
                  textType="small"
                  data-testid="collect-info-tab-customer-profile-mailing-address"
                />
              </>
            ) : (
              <EmptyAnswer textType="small" />
            )
          }
        />
      </FlexBox>
      <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
    </FlexBox>
  );
};

export default CustomerProfile;
