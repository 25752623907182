/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Button, { ButtonSize, ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { DocumentWithTickIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../hooks';
import { IDocument, IMaticPolicy } from '../../../interfaces';
import {
  DeliverableDocumentStatus,
  DocumentDeliveryChannel,
  DocumentDeliveryStatus,
  RecipientType
} from '../../../interfaces/IDocumentDelivery';
import useDocumentTypes from '../../../queries/document_types/useDocumentTypes';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { fileSizeFormatter } from '../../../utils/formatter';
import DocumentStatus from './DocumentStatus';
import GenerateInvoiceOnPolicy from './GenerateInvoiceOnPolicy';
import UploadPolicyDocument from './UploadPolicyDocument';

export interface IChannel {
  channel: DocumentDeliveryChannel;
  document_status: DeliverableDocumentStatus;
  delivery_status: DocumentDeliveryStatus;
  recipient_type: RecipientType;
}

const Document = ({
  doc,
  documentType,
  channels,
  policy
}: {
  doc: IDocument | null;
  documentType: string;
  channels: IChannel[];
  policy: IMaticPolicy;
}) => {
  const { person } = useGuidedSellingExperienceContext();
  const [generateInvoice, toggleGenerateInvoice] = useToggle(false);

  const { data: docTypes } = useDocumentTypes(true);

  const documentTypeTitle = docTypes?.document_types?.find(({ key }) => key === documentType)?.title;

  return (
    <>
      <FlexBox
        pv={spacings.px6}
        alignItemsCenter
        justifySpaceBetween
        border
        roundBorder
        mt={spacings.px12}
        p={spacings.px12}
      >
        <FlexBox
          gap={spacings.px12}
          alignItemsCenter
          css={css`
            max-width: 55%;
          `}
        >
          <Container border roundBorder p={spacings.px4}>
            <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
              <DocumentWithTickIcon color={doc ? colors.statusGreen : colors.grey30} height={24} width={24} />
            </Container>
          </Container>

          {doc ? (
            <FlexBox columnDirection fitParentWidth>
              <FlexBox alignItemsCenter gap={spacings.px8}>
                <Text className="fs-mask" singleLine title={doc.name}>
                  {doc.name}
                </Text>
                <Tag
                  transparent
                  label={documentTypeTitle || 'Unknown'}
                  textColor={documentTypeTitle ? colors.violet : colors.grey60}
                  backgroundColor={documentTypeTitle ? colors.violet : colors.grey60}
                  border
                  bold
                />
              </FlexBox>
              <Paragraph type="small" color={colors.grey60} singleLine>
                {fileSizeFormatter(doc.filesize)}
              </Paragraph>
            </FlexBox>
          ) : (
            <Text>{documentTypeTitle || 'Unknown'}</Text>
          )}
        </FlexBox>
        {!doc && documentType !== 'invoice' && docTypes && (
          <UploadPolicyDocument
            personGid={person!.gid}
            docTypes={docTypes.document_types}
            documentTypeKey={documentType}
            policy={policy}
          />
        )}

        {documentType === 'invoice' && !doc && (
          <Button variant={ButtonVariant.Secondary} size={ButtonSize.Small} onClick={toggleGenerateInvoice}>
            Generate
          </Button>
        )}
        {doc && <DocumentStatus channels={channels} document={doc} />}
      </FlexBox>
      {generateInvoice && person && (
        <GenerateInvoiceOnPolicy toggleGenerateInvoice={toggleGenerateInvoice} policy={policy} person={person} />
      )}
    </>
  );
};

export default Document;
