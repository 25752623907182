import { useMutation } from '@tanstack/react-query';

import { DocumentOwnerType, PolicyFileToProcess } from '../../../interfaces/IDocument';
import { IMaticPolicy, PolicyStatus, PolicyTransactionType } from '../../../interfaces/IPolicy';
import { PolicyType } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { DocumentRule } from '../../document_rules/useDocumentRules';
import { createDocument, deleteDocument } from '../person_documents/usePersonDocuments';

export interface UpdatePolicyRequest {
  person_gid: string;
  policy_id: number;

  transaction_type: PolicyTransactionType;
  policy_type: PolicyType;

  premium?: number | string;
  carrier_id?: number;
  status: PolicyStatus;

  primary_coverage?: number;
  deductible_type?: string;
  deductible_value?: string;
  policy_number?: string;
  sale_date: string;

  effective_date?: string;
  expiration_date?: string;
  cancellation_date?: string;
  payment_method?: string;
  cancellation_reason?: string;
  state?: string;
  document_rules_policies_attributes?: Pick<
    Required<DocumentRule>,
    'document_rule_id' | 'demand' | 'could_not_collect'
  >[];

  new_closing?: boolean;

  agent_id?: string | number;
  asset_gids?: string[];

  files?: PolicyFileToProcess[];

  trust_name?: string;
  llc_name?: string;
  primary_loan_gid?: string;
}

const uploadFiles = (policyParams: { person_gid: string; policy_id: number; files?: PolicyFileToProcess[] }) => {
  const policyFiles = policyParams.files || [];

  const filesToUpload = policyFiles.filter(file => !file.id && !file.deleted);

  return Promise.all(
    filesToUpload.map(file =>
      createDocument({
        personGid: policyParams.person_gid,
        request: {
          ...file,
          owner_id: policyParams.policy_id,
          owner_type: DocumentOwnerType.Policy
        }
      })
    )
  );
};

const deleteFiles = (policyParams: { person_gid: string; policy_id: number; files?: PolicyFileToProcess[] }) => {
  const policyFiles = policyParams.files || [];

  const filesToDelete = policyFiles.filter(file => file.id && file.deleted);

  return Promise.all(
    filesToDelete.map(file => deleteDocument({ personGid: policyParams.person_gid, documentId: file.id! }))
  );
};

export const processFiles = async (policyParams: {
  person_gid: string;
  policy_id: number;
  files?: PolicyFileToProcess[];
}) => await Promise.all([uploadFiles(policyParams), deleteFiles(policyParams)]);

export const updatePolicy = (data: UpdatePolicyRequest): Promise<{ policy: IMaticPolicy }> =>
  api.put(`/api/frontend/people/${data.person_gid}/policies/${data.policy_id}`, { body: data });

const useUpdatePolicy = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: updatePolicy,
    onSuccess: async (_, variables) => {
      await processFiles(variables);
      onSuccess?.();
    }
  });
};

export default useUpdatePolicy;
