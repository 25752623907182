import { skipToken, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IProposal } from '../../../interfaces/IProposal';
import api from '../../../services/api';

const PERSON_PROPOSALS_QUERY_KEY = 'person-proposals';

interface ProposalPayload {
  personGid: string;
  quoteGids: string[];
  engagementGid: string;
}

interface ProposalUpdatePayload {
  personGid: string;
  quoteGids: string[];
  proposalOptionGid: string;
  proposalGid: string;
}

interface TrackProposalSentPayload {
  personGid: string;
  proposalGid: string;
}

const getPersonProposals = ({ personGid, engagementGid }: { personGid: string; engagementGid: string }) =>
  api.get(`/api/frontend/people/${personGid}/proposals?engagement_gid=${engagementGid}`);

export const usePersonProposals = ({
  personGid,
  engagementGid
}: {
  personGid: string | undefined;
  engagementGid: string | undefined;
}) =>
  useQuery({
    queryKey: [PERSON_PROPOSALS_QUERY_KEY, personGid, engagementGid],
    queryFn:
      personGid && engagementGid
        ? (): Promise<{ proposals: IProposal[] }> => getPersonProposals({ personGid, engagementGid })
        : skipToken,
    select: data => data.proposals
  });

const createProposal = ({ personGid, quoteGids, engagementGid }: ProposalPayload): Promise<{ proposal: IProposal }> =>
  api.post(`/api/frontend/people/${personGid}/proposals`, {
    body: { quotes_gids: quoteGids, engagement_gid: engagementGid }
  });

export const useCreateProposal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createProposal,
    onSuccess: ({ proposal }, variables) => {
      return queryClient.setQueriesData(
        { queryKey: [PERSON_PROPOSALS_QUERY_KEY, variables.personGid] },
        (oldData: { proposals: IProposal[] } | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            proposals: [proposal, ...oldData.proposals]
          };
        }
      );
    }
  });
};

const updateProposal = ({
  personGid,
  quoteGids,
  proposalOptionGid,
  proposalGid
}: ProposalUpdatePayload): Promise<{ proposal: IProposal }> =>
  api.put(`/api/frontend/people/${personGid}/proposals/${proposalGid}`, {
    body: { proposal_option_gid: proposalOptionGid, quotes_gids: quoteGids }
  });

export const useUpdateProposal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateProposal,
    onSuccess: ({ proposal: updatedProposal }, variables) => {
      return queryClient.setQueriesData(
        { queryKey: [PERSON_PROPOSALS_QUERY_KEY, variables.personGid] },
        (oldData: { proposals: IProposal[] } | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            proposals: oldData.proposals.map(proposal => {
              if (proposal.gid === variables.proposalGid) {
                return updatedProposal;
              }

              return proposal;
            })
          };
        }
      );
    }
  });
};

const trackProposalSent = ({ personGid, proposalGid }: TrackProposalSentPayload): Promise<{ proposal: IProposal }> =>
  api.put(`/api/frontend/people/${personGid}/proposals/${proposalGid}/track_sent`);

export const useTrackProposalSent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: trackProposalSent,
    onSuccess: ({ proposal: updatedProposal }, variables) => {
      return queryClient.setQueriesData(
        { queryKey: [PERSON_PROPOSALS_QUERY_KEY, variables.personGid] },
        (oldData: { proposals: IProposal[] } | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            proposals: oldData.proposals.map(proposal => {
              if (proposal.gid === variables.proposalGid) {
                return updatedProposal;
              }

              return proposal;
            })
          };
        }
      );
    }
  });
};
