/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../components/core/FlexBox';
import FormModal from '../../../../components/core/FormModal';
import { SelectField } from '../../../../components/core/forms/fields';
import Modal, { ModalSize } from '../../../../components/core/Modal';
import Text from '../../../../components/core/Text';
import QuestionAnswerPair from '../../../../components/UIFlow/QuestionAnswerPair';
import { useToggle } from '../../../../hooks';
import { ILead, IPartner } from '../../../../interfaces';
import { IPaperTrailVersion } from '../../../../interfaces/IPaperTrailVersion';
import useLeadPartnerVersions from '../../../../queries/leads/partner/useLeadPartnerVersions';
import { updateLeadPartner } from '../../../../queries/leads/partner/useUpdateLeadPartner';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { dateTimeFormatter, SCOUTING_REPORT_DATE_TIME_FORMAT } from '../../../../utils/formatter';
import UserRoleTag from './_components/UserRoleTag';
import Version from './_components/Version';

const getChangesValueName = (change: string | undefined) => {
  return change ? change.split(' ')[1] : '';
};

const getPartnerName = (partner: string | undefined, partners: IPartner[]) => {
  return partners.find(p => p.key === partner)?.name || '';
};

const getPartnerNameChanges = ({
  changes,
  partners,
  initial
}: {
  changes: IPaperTrailVersion['changes'];
  partners: IPartner[];
  initial: boolean;
}): string => {
  const initialPartnerName = getPartnerName(getChangesValueName(changes?.[0]?.content), partners);
  const updatedPartnerName = getPartnerName(getChangesValueName(changes?.[1]?.content), partners);
  return initial ? initialPartnerName : `${initialPartnerName} → ${updatedPartnerName}`;
};

const Partner = ({ partners, lead }: { partners: IPartner[]; lead: ILead }) => {
  const queryClient = useQueryClient();
  const [openedEditPartnerModal, toggleEditOpenedPartnerModal] = useToggle(false);
  const [openedPartnerVersionsModal, toggleOpenedPartnerVersionsModal] = useToggle(false);

  const { data: partnerVersions } = useLeadPartnerVersions({ leadGid: lead.gid });
  const isPartnerUpdated = partnerVersions?.find(version => version.event === 'update');
  const isFieldEditable = authInfo.features.update_partner;

  return (
    <FlexBox pv={isFieldEditable ? 0 : spacings.px4} justifySpaceBetween>
      <QuestionAnswerPair
        questionLabel={
          <Text color={colors.grey60} type="small">
            Partner
          </Text>
        }
        answerLabel={
          <FlexBox gap={spacings.px4} fitParentWidth justifySpaceBetween>
            <FlexBox
              gap={spacings.px4}
              customCss={css`
                width: calc(100% - 32px);
              `}
              alignItemsCenter
            >
              <Text type="small" singleLine title={getPartnerName(lead.source_dimensions.partner, partners)}>
                {getPartnerName(lead.source_dimensions.partner, partners)}
              </Text>
              {isPartnerUpdated && (
                <Button
                  onClick={() => {
                    analytics.track('Lead operations viewed', { item: 'partner', lead_gid: lead.gid });
                    toggleOpenedPartnerVersionsModal();
                  }}
                  variant={ButtonVariant.PlainText}
                >
                  <Text underline dotted color={colors.statusOrange} type="small">
                    Updated
                  </Text>
                </Button>
              )}
            </FlexBox>
            {isFieldEditable && (
              <IconButton icon={ButtonIcons.Edit} color={colors.black} onClick={toggleEditOpenedPartnerModal} />
            )}
          </FlexBox>
        }
      />

      {openedEditPartnerModal && (
        <FormModal
          initialValues={{ partner: lead.source_dimensions.partner }}
          title="You're updating partner"
          confirmText="Update"
          confirmHandler={values => {
            analytics.track('Lead operations updated', { item: 'partner', lead_gid: lead.gid });

            updateLeadPartner({ leadId: lead.id, partner: values.partner! }).then(() => {
              toggleEditOpenedPartnerModal();
              queryClient.invalidateQueries();
            });
          }}
          cancelHandler={toggleEditOpenedPartnerModal}
          validationSchema={yup.object().shape({ partner: yup.string().required('Please select a partner') })}
          renderForm={() => (
            <SelectField
              options={partners.map(({ key, name }) => ({ key, value: name })) || []}
              id="partner"
              name="partner"
              label="Partner"
              required
              customCss={css`
                max-width: 320px;
              `}
            />
          )}
        />
      )}
      {openedPartnerVersionsModal && (
        <Modal
          title="Log of changes made with the partner field"
          showCancelButton={false}
          cancelHandler={toggleOpenedPartnerVersionsModal}
          size={ModalSize.large}
        >
          <FlexBox gap={spacings.px8} columnDirection p={spacings.px12}>
            {partnerVersions?.map(version => {
              return (
                <Version key={version.id} created={version.event === 'create'} columnsTemplate="38% 41% 19%">
                  <Text
                    color={colors.grey60}
                    type="small"
                    title={getPartnerNameChanges({
                      initial: version.event === 'create',
                      changes: version.changes,
                      partners
                    })}
                    singleLine
                  >
                    {getPartnerNameChanges({ initial: version.event === 'create', changes: version.changes, partners })}
                  </Text>
                  <FlexBox gap={spacings.px8} alignItemsCenter title={`by ${version.author_name}`}>
                    <Text color={colors.grey60} type="small" singleLine>
                      by {version.author_name}
                    </Text>
                    <UserRoleTag processedBy={version.processed_by} processedWithRole={version.processed_with_role} />
                  </FlexBox>
                  <Text color={colors.grey60} type="small" singleLine>
                    {dateTimeFormatter(version.created_at, SCOUTING_REPORT_DATE_TIME_FORMAT)}
                  </Text>
                </Version>
              );
            })}
          </FlexBox>
        </Modal>
      )}
    </FlexBox>
  );
};

export default Partner;
