/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import SystemMessage from '../../../../../components/core/Alert/SystemMessage';
import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import CollapsingContainer from '../../../../../components/core/CollapsingContainer';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { answerForDatapoint, questionWithSubQuestions } from '../../../../../components/core/forms/workflowHelpers';
import Paragraph, { ParagraphType } from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import UIFlowQuestionAnswerPair from '../../../../../components/UIFlow/UIFlowQuestionAnswerPair';
import { IOpportunity, IQuestion, IUIFlow } from '../../../../../interfaces';
import { OpportunityStatus } from '../../../../../interfaces/IOpportunity';
import colors, { opacities } from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';

interface HomeEligibilityProps {
  homeOpportunity: IOpportunity;
  homeEligibilityUIFlow: IUIFlow;
  isEditModeAvailable: boolean;
  onEdit: () => void;
}

const HomeEligibility = ({
  homeOpportunity,
  homeEligibilityUIFlow,
  isEditModeAvailable,
  onEdit
}: HomeEligibilityProps) => {
  const eligibilityRedFlags = homeEligibilityUIFlow.visibleQuestions().filter(({ content }) => {
    const question = content as IQuestion;
    const existingAnswer = answerForDatapoint(homeEligibilityUIFlow.answers, content.key)?.value;

    return question.variants?.some(variant => variant.underwriting_concern && variant.value === existingAnswer);
  });
  const eligibilityRedFlagsQuestions = eligibilityRedFlags
    .map(({ content }) => questionWithSubQuestions(homeEligibilityUIFlow.visibleQuestions(), content.key))
    .flat();

  const eligibilityOtherQuestions = homeEligibilityUIFlow
    .visibleQuestions()
    .filter(({ content }) => !eligibilityRedFlagsQuestions.find(({ content: { key } }) => key === content.key));

  const isEligibilityNotCollected = homeEligibilityUIFlow
    .visibleQuestions()
    .every(({ content }) => !answerForDatapoint(homeEligibilityUIFlow.answers, content.key)?.value);
  const isEligibilityFullyCollected = homeEligibilityUIFlow
    .visibleQuestions()
    .every(({ content }) => !!answerForDatapoint(homeEligibilityUIFlow.answers, content.key)?.value);
  const isEligibilityPartiallyCollected =
    homeEligibilityUIFlow
      .visibleQuestions()
      .some(({ content }) => !!answerForDatapoint(homeEligibilityUIFlow.answers, content.key)?.value) &&
    !isEligibilityFullyCollected;
  const isOpportunityNotQualified = homeOpportunity.status === OpportunityStatus.NotQualified;

  let backgroundColor;

  if (isOpportunityNotQualified) {
    backgroundColor = colors.redBackground;
  } else if (isEligibilityFullyCollected && !eligibilityRedFlagsQuestions.length) {
    backgroundColor = colors.greenBackground;
  } else {
    backgroundColor = colors.grey5;
  }

  return (
    <Container p={spacings.px8} roundBorder backgroundColor={backgroundColor}>
      <FlexBox justifySpaceBetween>
        <Paragraph type="small" bold>
          Eligibility
        </Paragraph>
        <IconButton
          data-testid="collect-info-tab-home-eligibility-edit-button"
          disabled={!isEditModeAvailable}
          icon={ButtonIcons.Edit}
          color={colors.black}
          {...(!isEditModeAvailable && {
            'data-tip': 'Edit is not available at this moment',
            'data-for': 'edit-hint'
          })}
          onClick={event => {
            event.stopPropagation();
            onEdit();
          }}
        />
        <Tooltip id="edit-hint" />
      </FlexBox>
      {isOpportunityNotQualified && (
        <SystemMessage
          type="error"
          heading={<Text type="small">{homeOpportunity.disqualification_message}</Text>}
          customCss={css`
            border: 1px solid ${colors.statusRed};
            background: ${colors.statusRed}${opacities.opacity_16};
          `}
          ph={spacings.px8}
          pv={spacings.px12}
        />
      )}
      {isEligibilityNotCollected && !isOpportunityNotQualified && <Paragraph type="small">Not collected</Paragraph>}
      {isEligibilityPartiallyCollected && !isOpportunityNotQualified && (
        <SystemMessage
          type="warning"
          heading={<Text type="small">Eligibilities not fully completed</Text>}
          ph={spacings.px8}
          pv={spacings.px12}
        />
      )}
      {isEligibilityFullyCollected && !eligibilityRedFlagsQuestions.length && (
        <Paragraph type="small">Nothing to worry about</Paragraph>
      )}
      {(isEligibilityPartiallyCollected || isEligibilityFullyCollected) && !!eligibilityRedFlagsQuestions.length && (
        <FlexBox columnDirection mt={spacings.px8} gap={spacings.px8}>
          {eligibilityRedFlagsQuestions.map(questionElement => (
            <UIFlowQuestionAnswerPair
              key={questionElement.content.key}
              textType="small"
              textColor={questionElement.content.secondary ? colors.grey60 : colors.black}
              questionElement={questionElement}
              answers={homeEligibilityUIFlow.answers}
            />
          ))}
        </FlexBox>
      )}
      {(isEligibilityPartiallyCollected || isEligibilityFullyCollected) && !!eligibilityOtherQuestions.length && (
        <CollapsingContainer
          title={eligibilityRedFlags.length ? 'No concerns about everything else' : 'No concerns'}
          iconPosition="left"
          openedByDefault={false}
          titleSize={ParagraphType.Small}
          mt={spacings.px8}
          preserveChildrenInClosedState
          maxContentHeight={500}
          buttonTestId="collect-info-tab-home-eligibility-other-questions"
        >
          <FlexBox columnDirection gap={spacings.px8}>
            {eligibilityOtherQuestions.map(questionElement => (
              <UIFlowQuestionAnswerPair
                key={questionElement.key}
                textType="small"
                textColor={questionElement.content.secondary ? colors.grey60 : colors.black}
                questionElement={questionElement}
                answers={homeEligibilityUIFlow.answers}
              />
            ))}
          </FlexBox>
        </CollapsingContainer>
      )}
    </Container>
  );
};

export default HomeEligibility;
