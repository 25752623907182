/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import React from 'react';

import editIcon from '../../../assets/new_icons/edit.svg';
import { EmptyResults } from '../../../components/common';
import Checkbox from '../../../components/common/Checkbox/Checkbox';
import DropDownMenu from '../../../components/common/DropDownMenu/DropDownMenu';
import NewTooltip from '../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../components/core/FlexBox';
import { NoteIcon } from '../../../components/core/icons';
import Tag from '../../../components/core/Tag';
import { Translations } from '../../../constants';
import { RED_ERROR } from '../../../constants/colors';
import Sortable, { ORDER_TYPES } from '../../../hocs/Sortable';
import { IScheduledCall } from '../../../interfaces/IScheduledCall';
import { ITask } from '../../../interfaces/ITask';
import { capitalize, DASHBOARD_DATE_TIME_FORMAT, dateFormatter, dateTimeFormatter } from '../../../utils/formatter';
import EditScheduledCall from '../../ScheduleCall/EditScheduledCall';
import { isCallCompleted, isCallFailed } from '../../ScheduleCall/helpers';
import Description from '../../ScheduledCalls/Description';

const TYPE = 'kind';
const DATE = 'date';
const COMPLETED = 'completed';
const PRIORITY = 'priority';

const noop = () => {};

interface IScheduledCallColumns {
  leadGid: string | undefined;
  scheduledCall: IScheduledCall;
  index: number;
  refetchInteractions: () => Promise<any>;
}

const ScheduledCallColumns = ({
  leadGid,
  scheduledCall,
  index,
  refetchInteractions
}: IScheduledCallColumns): JSX.Element => {
  const [isExpanded, setExpanded] = React.useState(false);

  const callTime = dateTimeFormatter(scheduledCall.run_at, DASHBOARD_DATE_TIME_FORMAT);

  return (
    <>
      <tr className="task" data-testid={`${index}-task-row`}>
        <td data-testid={`${index}-task-scheduled-call-column`}>
          <FlexBox>
            <Tag label="Scheduled Call" />
          </FlexBox>
        </td>
        <td data-testid={`${index}-task-assignee-column`}>
          {scheduledCall.assignee ? scheduledCall.assignee.name : 'Unknown user'}
        </td>
        <td>{Translations.taskPriority('normal')}</td>
        <td data-testid={`${index}-task-show-note-column`}>
          <NoteIcon
            onClick={() => setExpanded(expanded => !expanded)}
            css={css`
              align-self: center;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            `}
          />
        </td>
        <td data-testid={`${index}-task-due-date-column`}>
          {isCallFailed(scheduledCall) ? (
            <span
              data-tip={dateTimeFormatter(scheduledCall.run_at, DASHBOARD_DATE_TIME_FORMAT)}
              data-for="scheduled-call-status"
              css={css`
                color: ${RED_ERROR};
                width: fit-content;
                text-decoration: underline dotted;
              `}
            >
              {capitalize(scheduledCall.status)}
            </span>
          ) : (
            callTime
          )}
        </td>
        <td data-testid={`${index}-task-completed-checkbox-column`}>
          {!isCallFailed(scheduledCall) && (
            <Checkbox
              data-tip='Scheduled call is marked as "completed" automatically after call is finished'
              data-for="scheduled-call-status"
              name={`completed-${index}`}
              value={isCallCompleted(scheduledCall)}
              onChange={noop}
              disabled
            />
          )}
        </td>
        <td data-testid={`${index}-task-edit-column`}>
          {!(isCallFailed(scheduledCall) || isCallCompleted(scheduledCall)) && (
            <EditScheduledCall
              scheduledCall={scheduledCall}
              leadGid={leadGid}
              candidateGid={undefined}
              refetch={refetchInteractions}
            />
          )}
        </td>
      </tr>
      {isExpanded && (
        <tr data-testid={`${index}-task-expanded-note`}>
          <td colSpan={8}>
            <Description gid={scheduledCall.person_gid} created_at={scheduledCall.created_at} />
          </td>
        </tr>
      )}
    </>
  );
};

const formatDueDate = (task: ITask) =>
  task.due_time ? dateTimeFormatter(task.due_time, DASHBOARD_DATE_TIME_FORMAT) : dateFormatter(task.due_date);

interface ITasksList {
  leadGid: string | undefined;
  tasks: ITask[];
  scheduledInteractions: IScheduledCall[];
  onTaskEditorOpen: (task: ITask) => void;
  onTaskComplete: (task: ITask) => void;
  isLoading: boolean;
  refetchInteractions: () => Promise<any>;
}

const TasksList = ({
  leadGid,
  tasks,
  scheduledInteractions,
  onTaskEditorOpen,
  onTaskComplete,
  isLoading,
  refetchInteractions
}: ITasksList): JSX.Element => {
  const allTasks = [...tasks, ...scheduledInteractions];

  const taskActions = (task: ITask) => [
    {
      icon: editIcon,
      label: 'Edit',
      action: () => onTaskEditorOpen(task),
      classes: 'edit'
    }
  ];

  const taskColumns = (task: ITask, index: number) => {
    const onComplete = () => onTaskComplete(task);

    return (
      <tr className="task" key={task.id} data-testid={`${index}-task-row`}>
        <td data-testid={`${index}-task-type-column`}>{Translations.taskKind(task.kind)}</td>
        <td data-testid={`${index}-task-assignee-column`}>{task.assignee && task.assignee.name}</td>
        <td data-testid={`${index}-task-priority-column`}>{Translations.taskPriority(task.priority)}</td>
        <td className="preformatted-text fs-mask" data-testid={`${index}-task-note-column`}>
          {task.description}
        </td>
        <td data-testid={`${index}-task-due-date-column`}>{formatDueDate(task)}</td>
        <td data-testid={`${index}-task-completed-checkbox-column`}>
          <Checkbox name={`completed-${index}`} value={task.completed} onChange={onComplete} />
        </td>
        <td data-testid={`${index}-task-edit-column`}>
          <DropDownMenu tipId={index.toString()} items={taskActions(task)} right wrapperTestId="task-actions" />
        </td>
      </tr>
    );
  };

  return allTasks.length ? (
    <>
      <Global
        styles={css`
          .task-tab {
            .task-list-container {
              padding: 1px;
            }

            .task-list {
              margin-top: 2rem;
            }
          }
        `}
      />
      <Sortable
        sortableColumns={{
          [TYPE]: ORDER_TYPES.STRING,
          [DATE]: ORDER_TYPES.DATE,
          [PRIORITY]: ORDER_TYPES.STRING,
          [COMPLETED]: ORDER_TYPES.NUMBER
        }}
        defaultColumn={DATE}
        list={allTasks}
        render={({ list, sortBy, getSortableClasses }: any) => (
          <section
            css={css`
              padding: 1px;
              opacity: ${isLoading ? 0.5 : 1};
            `}
          >
            <table className="task-list table">
              <thead>
                <tr>
                  <th style={{ minWidth: '180px' }} className={getSortableClasses(TYPE)} onClick={sortBy(TYPE)}>
                    Type
                  </th>
                  <th style={{ minWidth: '180px' }}>Assignee</th>
                  <th style={{ minWidth: '160px' }} className={getSortableClasses(PRIORITY)} onClick={sortBy(PRIORITY)}>
                    Priority
                  </th>
                  <th style={{ minWidth: '140px' }}>Note</th>
                  <th style={{ minWidth: '220px' }} className={getSortableClasses(DATE)} onClick={sortBy(DATE)}>
                    Due date (your time)
                  </th>
                  <th
                    style={{ minWidth: '180px' }}
                    className={getSortableClasses(COMPLETED)}
                    onClick={sortBy(COMPLETED)}
                  >
                    Completed
                  </th>
                  <th className="actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.map((task: any, index: number) => {
                  return task.kind ? (
                    taskColumns(task, index)
                  ) : (
                    <ScheduledCallColumns
                      leadGid={leadGid}
                      key={`${task.id}-${task.run_at}`}
                      scheduledCall={task}
                      index={index}
                      refetchInteractions={refetchInteractions}
                    />
                  );
                })}
              </tbody>
            </table>
            <NewTooltip id="scheduled-call-status" />
          </section>
        )}
      />
    </>
  ) : (
    <EmptyResults message="No tasks yet" />
  );
};

export default TasksList;
