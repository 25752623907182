/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { spacings } from '../../theme/variables';
import Container from './Container';
import FlexBox from './FlexBox';
import Text from './Text';

const DottedTag = ({ text, color, icon, ...rest }: { text: string; color: string; icon?: JSX.Element }) => {
  return (
    <FlexBox {...rest} alignItemsCenter gap={icon ? 0 : spacings.px4} fitParentWidth>
      {icon ? (
        icon
      ) : (
        <Container
          customCss={css`
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: ${color};
          `}
        />
      )}
      <Text type="small" singleLine ml={icon ? spacings.px4 : 0}>
        {text}
      </Text>
    </FlexBox>
  );
};

export default DottedTag;
