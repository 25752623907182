const featureFlags = {
  get scoutingReportRedirect() {
    return !!window.__amp_feature_flags.scouting_report_redirect;
  },
  get hideCallIndicator() {
    return !!window.__amp_feature_flags.hide_call_indicator;
  },
  get hideLeadTasksTab() {
    return !!window.__amp_feature_flags.hide_lead_tasks_tab;
  },
  get hideLeadLoansTab() {
    return !!window.__amp_feature_flags.hide_lead_loans_tab;
  },
  get hideLeadAssetsTab() {
    return !!window.__amp_feature_flags.hide_lead_assets_tab;
  },
  get hideLeadDocumentsTab() {
    return !!window.__amp_feature_flags.hide_lead_files_tab;
  },
  get hideLeadNotesTab() {
    return !!window.__amp_feature_flags.hide_lead_notes_tab;
  },
  get hideLeadQuotesTab() {
    return !!window.__amp_feature_flags.hide_lead_quotes_tab;
  },
  get hideCustomerHouseholdMembersTab() {
    return !!window.__amp_feature_flags.hide_customer_household_members_tab;
  },
  get hideCustomerTasksTab() {
    return !!window.__amp_feature_flags.hide_customer_tasks_tab;
  },
  get hideCustomerLoansTab() {
    return !!window.__amp_feature_flags.hide_customer_loans_tab;
  },
  get hideCustomerAssetsTab() {
    return !!window.__amp_feature_flags.hide_customer_assets_tab;
  },
  get hideCustomerFilesTab() {
    return !!window.__amp_feature_flags.hide_customer_files_tab;
  },
  get hideCustomerNotesTab() {
    return !!window.__amp_feature_flags.hide_customer_notes_tab;
  },
  get hideCustomerQuotesTab() {
    return !!window.__amp_feature_flags.hide_customer_quotes_tab;
  },
  get hideCustomerPriorPoliciesTab() {
    return !!window.__amp_feature_flags.hide_customer_prior_policies_tab;
  },
  get hideDashboardCreateButton() {
    return !!window.__amp_feature_flags.hide_dashboard_create_button;
  },
  get hideLeadDisposition() {
    return !!window.__amp_feature_flags.hide_lead_disposition;
  },
  get clickToTwilioEmail() {
    return !!window.__amp_feature_flags.click_to_twilio_email;
  },
  get productAnnouncements() {
    return !!window.__amp_feature_flags.product_announcements;
  },
  get activeCallRestriction() {
    return !!window.__amp_feature_flags.active_call_restriction;
  },
  get assignLeadAfterCallScheduling() {
    return !!window.__amp_feature_flags.assign_lead_after_call_scheduling;
  },
  get dynamicTips() {
    return (
      !!window.__amp_feature_flags.dynamic_tip_on_summary ||
      !!window.__amp_feature_flags.dynamic_tip_on_data_collection ||
      !!window.__amp_feature_flags.dynamic_tip_on_quotes ||
      !!window.__amp_feature_flags.life_insurance_pitch
    );
  },
  get newFcraPrompt() {
    return !!window.__amp_feature_flags.new_fcra_prompt;
  },
  get issuePolicyConsent() {
    return !!window.__amp_feature_flags.issue_policy_consent;
  },
  get dynamicTipOnSummary() {
    return !!window.__amp_feature_flags.dynamic_tip_on_summary;
  },
  get dynamicTipOnDataCollection() {
    return !!window.__amp_feature_flags.dynamic_tip_on_data_collection;
  },
  get dynamicTipOnQuotes() {
    return !!window.__amp_feature_flags.dynamic_tip_on_quotes;
  },
  get lifeInsurancePitch() {
    return !!window.__amp_feature_flags.life_insurance_pitch;
  },
  get leadClassificationCheck() {
    return !!window.__amp_feature_flags.lead_classification_check;
  },
  get displayDisqualifyButton() {
    return !!window.__amp_feature_flags.display_disqualify_button;
  },
  get superISRFeedbackUI() {
    return !!window.__amp_feature_flags.super_isr_feedback_ui;
  },
  get homeDataCollectionHints() {
    return !!window.__amp_feature_flags.home_data_collection_hints;
  },
  get displayCollectedBySuperISRTip() {
    return !!window.__amp_feature_flags.display_collected_by_super_isr_tip;
  },
  get vehicleProfileAdditionalDriversNudge() {
    return !!window.__amp_feature_flags.additional_drivers_nudge;
  },
  get hideSensitiveSataForPnc() {
    return !!window.__amp_feature_flags.hide_sensitive_data_for_pnc;
  },
  get propertyOtherStructuresQuestion() {
    return !!window.__amp_feature_flags.property_other_structures_question;
  },
  get vehicleAdditionalQuestions() {
    return !!window.__amp_feature_flags.vehicle_additional_questions;
  },
  get newQuotesUI() {
    return !!window.__amp_feature_flags.new_quotes_ui;
  },
  get proposalBuilderPage() {
    return !!window.__amp_feature_flags.proposal_builder_page;
  },
  get hideCallIndicatorAssignmentControls() {
    return !!window.__amp_feature_flags.hide_call_indicator_assignment_controls;
  },
  get cstGuidedSellingExperience() {
    return !!window.__amp_feature_flags.cst_guided_selling_experience;
  },
  get homeEligibilityPropertyUnderMaintenance() {
    return !!window.__amp_feature_flags.home_eligibility_property_under_maintenance;
  },
  get disableOldQuotesGetQuotes() {
    return !!window.__amp_feature_flags.disable_old_quotes_get_quotes;
  },
  get displayTCPAConsent() {
    return !!window.__amp_feature_flags.display_tcpa_consent;
  },
  get enablePolicyDocumentsUI() {
    return !!window.__amp_feature_flags.enable_policy_documents_ui;
  },
  get winBackLeadsChanges() {
    return !!window.__amp_feature_flags.win_back_leads_changes;
  },
  get obtainLicenseNumberUI() {
    return !!window.__amp_feature_flags.obtain_license_number;
  },
  get celinkLendersDropdown() {
    return !!window.__amp_feature_flags.celink_lenders_dropdown;
  },
  get crossSellLeadChanges() {
    return !!window.__amp_feature_flags.cross_sell_leads_changes;
  },
  get relinkHomeOpportunity() {
    return !!window.__amp_feature_flags.relink_home_opportunity;
  },
  get crossSellOtherOpportunities() {
    return !!window.__amp_feature_flags.cross_sell_lead_with_other_opportunities;
  },
  get expiredQuotesUI() {
    return !!window.__amp_feature_flags.expired_quotes_ui;
  },
  get customerView() {
    return !!window.__amp_feature_flags.customer_view;
  },
  get nonAdmittedLicenseCheck() {
    return !!window.__amp_feature_flags.non_admitted_license_check;
  }
};

export default featureFlags;
