import { useQueryClient } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import * as yup from 'yup';

import alert from '../../../components/core/Alert';
import FlexBox from '../../../components/core/FlexBox';
import FormModal from '../../../components/core/FormModal';
import { RadioGroupField } from '../../../components/core/forms/fields';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { collectPolicyAssetsAddresses } from '../../../components/GenerateInvoiceModal/helpers';
import generateDocxReport, { CarrierAddress } from '../../../components/PolicyList/generateDocxReport';
import { IMaticPolicy, IPerson } from '../../../interfaces';
import { DocumentOwnerType } from '../../../interfaces/IDocument';
import useAssignees from '../../../queries/assignees/useAssignees';
import useCarrier from '../../../queries/carriers/useCarrier';
import useDocumentTypes from '../../../queries/document_types/useDocumentTypes';
import { DOCS_QUERY_KEY, useCreateDocument } from '../../../queries/people/person_documents/usePersonDocuments';
import { POLICY_DELIVERIES_QUERY_KEY } from '../../../queries/people/person_policies/usePolicyDeliveries';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';

const GenerateInvoiceOnPolicy = ({
  toggleGenerateInvoice,
  policy,
  person
}: {
  toggleGenerateInvoice: () => void;
  policy: IMaticPolicy;
  person: IPerson;
}) => {
  const queryClient = useQueryClient();
  const { data: policyCarrier, isLoading: isLoadingPolicyCarrier } = useCarrier(policy.carrier.id);
  const { data: assignees, isLoading: isLoadingAssignees } = useAssignees();
  const { data: docTypes, isLoading: isLoadingDocumentTypes } = useDocumentTypes();
  const { mutateAsync: createDocument, isPending } = useCreateDocument({ attachDocs: false });

  return (
    <FormModal
      title="Generate invoice"
      confirmText="Generate"
      cancelText="Cancel"
      cancelHandler={toggleGenerateInvoice}
      confirmHandler={values => {
        const reportTitle = `${person?.first_name}_${person?.last_name}_` + `${policy.policy_number}_invoice.docx`;

        generateDocxReport({
          title: reportTitle,
          person,
          policy,
          carrier: {
            address: policyCarrier?.[values.mailing_method as CarrierAddress],
            name: policyCarrier?.name
          },
          agentName: assignees?.find(({ id }) => id === policy.agent_id)?.name,
          assetsAddresses: collectPolicyAssetsAddresses(policy),
          saveFile: false
        }).then(blob => {
          if (blob) {
            const invoiceDocTypeId = docTypes?.document_types?.find(
              document_type => document_type.key === 'invoice'
            )?.id;

            if (invoiceDocTypeId) {
              createDocument({
                personGid: person.gid,
                request: {
                  file: new File([blob], reportTitle),
                  uid: crypto.randomUUID(),
                  owner_id: policy.id,
                  owner_type: DocumentOwnerType.Policy,
                  document_type_id: invoiceDocTypeId
                }
              }).then(() => {
                queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, person.gid] });
                queryClient.invalidateQueries({ queryKey: [POLICY_DELIVERIES_QUERY_KEY, person.gid, policy.id] });
                alert({
                  message: 'Invoice was added to your files.',
                  options: {
                    autoClose: 2000
                  }
                }).info();
              });
            }
          }
        });
        toggleGenerateInvoice();
      }}
      confirmationInProgress={isPending}
      validationSchema={yup.object({ mailing_method: yup.string().required('Please choose mailing method') })}
      initialValues={{ mailing_method: CarrierAddress.OvernightAddress }}
      renderForm={() => {
        if (isLoadingPolicyCarrier || isLoadingAssignees || isLoadingDocumentTypes) {
          return <Skeleton height={100} />;
        }
        return (
          <FlexBox columnDirection gap={spacings.px24}>
            <RadioGroupField
              columnDirection
              label={() => <Paragraph bold>Choose mailing method</Paragraph>}
              id="mailing_method"
              name="mailing_method"
              options={[
                policyCarrier?.overnight_address?.full && {
                  id: CarrierAddress.OvernightAddress,
                  label: 'Overnight address',
                  description: (
                    <Text type="small" color={colors.grey60}>
                      {policyCarrier?.overnight_address?.full}
                    </Text>
                  ),
                  value: CarrierAddress.OvernightAddress
                },
                policyCarrier?.remittance_address?.full && {
                  id: CarrierAddress.RemittanceAddress,
                  value: CarrierAddress.RemittanceAddress,
                  description: (
                    <Text type="small" color={colors.grey60}>
                      {policyCarrier?.remittance_address?.full}
                    </Text>
                  ),
                  label: 'Remittance address'
                }
              ].filter(Boolean)}
            />
          </FlexBox>
        );
      }}
    />
  );
};

export default GenerateInvoiceOnPolicy;
