/** @jsxImportSource @emotion/react */
import React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import usePerson from '../../../../queries/people/usePerson';
import usePersonAddresses from '../../../../queries/people/usePersonAddresses';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import { spacings } from '../../../../theme/variables';
import TabSkeleton from '../../_components/VerticalTabs/TabSkeleton';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import CustomerProfile from './CustomerProfile';

const PeopleTab = ({ personGid }: { personGid: string }) => {
  const { data: person } = usePerson(personGid);
  const { data: relatedPeople } = usePersonRelatedPeople(personGid);
  const { data: personAddresses } = usePersonAddresses(personGid);

  const isLoadingCustomerProfile = !person || !personAddresses || !relatedPeople;

  return (
    <FlexBox columnDirection fitParentHeight>
      <VerticalTabHeader>
        <FlexBox alignItemsCenter>
          <Paragraph type="large">People</Paragraph>
        </FlexBox>
      </VerticalTabHeader>

      <VerticalTabContent>
        {isLoadingCustomerProfile && <TabSkeleton ph={spacings.px12} pb={spacings.px12} />}
        {!isLoadingCustomerProfile && <CustomerProfile person={person} relatedPeople={relatedPeople || []} />}
      </VerticalTabContent>
    </FlexBox>
  );
};

export default PeopleTab;
