/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { components } from 'react-select';

import emptyImage from '../../assets/img/empty.svg';
import Tooltip from '../../components/common/Tooltip/NewTooltip';
import Button, { ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import { Add2Icon, CarIcon, HomeIcon } from '../../components/core/icons';
import Select from '../../components/core/inputs/Select';
import Paragraph from '../../components/core/Paragraph';
import Text from '../../components/core/Text';
import { Configuration } from '../../constants';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../hooks';
import { DispositionType } from '../../interfaces/IDisposition';
import { InsurableInterest } from '../../interfaces/IPolicyType';
import { IProposal } from '../../interfaces/IProposal';
import { isMoreThanOneWeekOld } from '../../interfaces/IQuote';
import { usePersonProposals } from '../../queries/people/proposals/usePersonProposal';
import colors from '../../theme/colors';
import { InputSize, spacings } from '../../theme/variables';
import { dateTimeFormatter } from '../../utils/formatter';
import { isImageCached } from '../../utils/object';
import { useCurrentDispositionSubscriptionContext } from '../GuidedSellingExperience/_hooks';
import { GuidedSellingPathnames } from '../GuidedSellingExperience/navigation';
import NewMessage from '../NewMessage';
import { PROPOSAL_IFRAME_ID } from './_helpers';
import { ProposalContext, useProposalContext } from './_hooks/useProposalContext';
import ProposalSummary from './ProposalSummary';

const ProposalCustomOption = ({ children, data: option, ...rest }: any) => {
  const isMasked = (rest.selectProps as any)['fsMask'];
  const isSelected = rest.isSelected;

  return (
    <components.Option {...rest} className={isMasked ? 'fs-mask' : ''}>
      <FlexBox columnDirection fitParentWidth m={spacings.px4} gap={spacings.px4}>
        <FlexBox justifySpaceBetween fitParentWidth alignItemsFlexEnd>
          {option.carriers({ isSelected })}
          <Text bold color={isSelected ? colors.white : colors.black}></Text>
        </FlexBox>
      </FlexBox>
    </components.Option>
  );
};

const getTooltipText = (currentProposal: IProposal | undefined | null) => {
  if (!currentProposal) {
    return 'Please select or create a proposal to review with customer';
  }

  if (currentProposal?.canceled_at) {
    return 'Proposal has been canceled';
  }

  if (isMoreThanOneWeekOld(currentProposal.created_at)) {
    return 'Proposal is more than one week old';
  }

  return '';
};

const generateProposalTitle = (proposal: IProposal) => {
  const expired = isMoreThanOneWeekOld(proposal.created_at);
  const isSent = !!proposal.sent_at;
  const isCanceled = !!proposal.canceled_at;

  if (isCanceled) {
    return `Canceled at ${dateTimeFormatter(proposal.canceled_at)}`;
  }

  return isSent
    ? `Sent at ${dateTimeFormatter(proposal.sent_at)} ${expired ? '(Expired)' : ''}`
    : `Created at ${dateTimeFormatter(proposal.created_at)} ${expired ? '(Expired)' : ''}`;
};

const ProposalBuilder = ({ proposals }: { proposals: IProposal[] }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { leadGid, person } = useGuidedSellingExperienceContext();
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const [isSendMessageModalOpened, toggleSendMessageModalOpened] = useToggle(false);
  const [imageLoaded, setImageLoaded] = React.useState(() => isImageCached(emptyImage));

  const { setCurrentProposalGid, currentProposalGid, resetQuotesForOpportunities } = useProposalContext();
  const currentProposal = proposals.find(proposal => proposal.gid === currentProposalGid);

  const showFinalizeButton =
    currentDisposition &&
    (currentDisposition.disposition_type === DispositionType.Pipeline ||
      currentDisposition.disposition_type === DispositionType.Qualified);
  const proposalUrl = currentProposal ? `${Configuration.customerPortalPublicUrl}/proposal/${currentProposal.gid}` : '';

  const proposalsOptions = proposals.map(proposal => ({
    key: proposal.gid,
    value: generateProposalTitle(proposal),
    created_at: proposal.created_at,
    sent_at: proposal.sent_at,
    carriers: (({ isSelected }: { isSelected: boolean }) => {
      return (
        <FlexBox columnDirection gap={spacings.px8}>
          <Text type="tiny" color={isSelected ? colors.white : colors.grey60}>
            {generateProposalTitle(proposal)}
          </Text>
          <FlexBox
            customCss={css`
              flex-wrap: wrap;
            `}
            fitParentWidth
          >
            {proposal.proposal_options?.[0]?.quotes.map((quote, index) => (
              <FlexBox key={quote.gid} alignItemsCenter gap={spacings.px4}>
                {quote.attributes.insurable_interest === InsurableInterest.RealProperty ? <HomeIcon /> : <CarIcon />}
                <div
                  css={css`
                    background-position: left;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 30px;
                    width: 80px;
                    filter: ${isSelected ? 'invert(100%)' : ''};
                    ${quote.attributes.carrier.logo ? `background-image: url(${quote.attributes.carrier.logo})` : ''};
                  `}
                  title={quote.attributes.carrier.name}
                />
                {proposal.proposal_options[0] && index < proposal.proposal_options[0].quotes.length - 1 && (
                  <Container m={spacings.px4}>
                    <Add2Icon width={12} />
                  </Container>
                )}
              </FlexBox>
            ))}
          </FlexBox>
        </FlexBox>
      );
    }) as unknown as JSX.Element
  }));

  return (
    <Container
      customCss={css`
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
      `}
    >
      <FlexBox
        fitParentHeight
        customCss={css`
          overflow: auto;
        `}
      >
        <FlexBox
          columnDirection
          customCss={css`
            border-right: 1px solid ${colors.grey10};
            min-width: 485px;
            height: 100%;
            overflow: auto;
          `}
        >
          <FlexBox
            p={spacings.px12}
            customCss={css`
              border-bottom: 1px solid ${colors.grey10};
            `}
            justifySpaceBetween
            pr={spacings.px24}
            alignItemsCenter
          >
            <Paragraph type="large">Build proposal</Paragraph>

            <Select
              options={proposalsOptions}
              onChange={value => {
                setCurrentProposalGid(value);
                resetQuotesForOpportunities();
              }}
              inputSize={InputSize.Large}
              value={currentProposal?.gid}
              customStyles={{
                control: () => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '310px',
                  border: `1px solid ${colors.grey10}`,
                  borderRadius: '8px'
                })
              }}
              createOptionFromSearch={false}
              customComponents={{ Option: ProposalCustomOption as unknown as React.ReactNode }}
              menuOptions={
                <>
                  <hr
                    css={css`
                      margin: 0 10px 10px 10px;
                    `}
                  />
                  <Button
                    onClick={e => {
                      setCurrentProposalGid(undefined);
                      resetQuotesForOpportunities();
                      e.stopPropagation();
                    }}
                    variant={ButtonVariant.PlainText}
                    data-testid="new-proposal-button"
                    customCss={css`
                      padding: 4px 12px 16px 12px;
                      text-align: left;
                      width: 100%;
                      color: ${colors.azure50};
                    `}
                  >
                    + New proposal
                  </Button>
                </>
              }
            />
          </FlexBox>
          <ProposalSummary currentProposal={currentProposal} key={currentProposal?.gid} />
        </FlexBox>

        {!currentProposal && (
          <FlexBox columnDirection alignItemsCenter gap={spacings.px40} p={spacings.px40}>
            <img src={emptyImage} alt="Empty" width={344} onLoad={() => setImageLoaded(true)} />
            {imageLoaded && (
              <Paragraph
                type="large"
                customCss={css`
                  text-align: center;
                `}
              >
                Your proposal will be displayed here after creation. To start building a proposal, please select the
                insurance products you want to include.
              </Paragraph>
            )}
          </FlexBox>
        )}
        {currentProposal && (
          <FlexBox
            alignItemsCenter
            css={css`
              overflow-y: auto;
              margin: 0 auto;
            `}
          >
            <iframe
              css={css`
                border: none;
              `}
              id={PROPOSAL_IFRAME_ID}
              height="100%"
              width="900px"
              key="Proposal"
              src={`${proposalUrl}?referrer=amp`}
              title="Customer proposal"
              loading="lazy"
            ></iframe>
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox
        justifyRight
        p={spacings.px24}
        gap={spacings.px12}
        customCss={css`
          border-top: 1px solid ${colors.grey10};
        `}
      >
        {showFinalizeButton && (
          <Button onClick={() => navigate(`${GuidedSellingPathnames.Finalize}${search}`)}>Finalize</Button>
        )}
        <Button
          onClick={toggleSendMessageModalOpened}
          disabled={
            !person ||
            !currentProposal ||
            !!currentProposal.canceled_at ||
            isMoreThanOneWeekOld(currentProposal.created_at)
          }
          data-tip={getTooltipText(currentProposal)}
          data-for="review-with-customer-tooltip"
        >
          Review with customer
        </Button>
        <Tooltip id="review-with-customer-tooltip" />
      </FlexBox>

      {isSendMessageModalOpened && person && currentProposal && leadGid && (
        <NewMessage
          cancelHandler={() => {
            toggleSendMessageModalOpened();
          }}
          person={person}
          proposalGid={currentProposal.gid}
          proposalUrl={proposalUrl}
          leadGid={leadGid}
          trackProposalSending={!currentProposal.sent_at}
        />
      )}
    </Container>
  );
};

const ProposalBuilderV2 = () => {
  const { personGid, leadGid } = useGuidedSellingExperienceContext();
  const { data: proposals } = usePersonProposals({ personGid, engagementGid: leadGid });

  // TODO: add loader
  if (!proposals) {
    return null;
  }

  return (
    <ProposalContext.Provider initialValue={proposals[0]?.gid}>
      <ProposalBuilder proposals={proposals} />
    </ProposalContext.Provider>
  );
};

export default ProposalBuilderV2;
