/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as yup from 'yup';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { InputField, MultiSelectField, NumericField, SelectField } from '../../components/core/forms/fields';
import { DollarIcon, LinkSharpIcon, PercentageIcon } from '../../components/core/icons';
import Select from '../../components/core/inputs/Select';
import Text from '../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IHome, IQuote } from '../../interfaces';
import { coverageByKey, DeductibleType, LimitType, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../interfaces/IPolicyType';
import { QuoteSource } from '../../interfaces/IQuote';
import useCarriers from '../../queries/carriers/useCarriers';
import { AGGREGATED_QUOTES_QUERY_KEY } from '../../queries/people/person_quotes/useAggregatedQuotes';
import { useCreateQuote } from '../../queries/people/person_quotes/useCreateQuote';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { moneyFormatter, typedMoneyFormatter } from '../../utils/formatter';
import { REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';
import { SmallDropdownIndicator } from './_components';
import { buildExcludedCoverages, buildIncludedCoverages, HOME_COVERAGES_OPTIONS } from './_helpers';

const buildInitialValues = (quote?: IQuote) => {
  const lossOfUseLimit = coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.LossOfUse)?.limit_value || '';
  return {
    carrier_key: quote?.attributes.carrier.key || '',
    premium: quote?.attributes.premium || '',
    [PolicyCoverageKey.Dwelling]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.limit_value || '',
    deductible: coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.deductible_value || '',
    [PolicyCoverageKey.OtherStructures]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.OtherStructures)?.limit_value || '',
    [PolicyCoverageKey.PersonalProperty]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalProperty)?.limit_value || '',
    lossOfUseLimitType: lossOfUseLimit && Number(lossOfUseLimit) <= 100 ? LimitType.MonthlyLimit : LimitType.MoneyLimit,
    [PolicyCoverageKey.LossOfUse]: lossOfUseLimit,
    [PolicyCoverageKey.PersonalLiability]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalLiability)?.limit_value || '',
    [PolicyCoverageKey.MedicalPayments]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.MedicalPayments)?.limit_value || '',
    included_coverages: quote?.attributes.coverages
      ?.map(coverage =>
        HOME_COVERAGES_OPTIONS.includes(coverage.key) &&
        coverage.deductible_description !== 'Not included' &&
        coverage.limit_description !== 'Not included'
          ? coverage.key
          : undefined
      )
      .filter(Boolean) || [PolicyCoverageKey.WindHail],
    excluded_coverages:
      quote?.attributes.coverages
        ?.map(coverage =>
          coverage.deductible_description === 'Not included' || coverage.limit_description === 'Not included'
            ? coverage.key
            : undefined
        )
        .filter(Boolean) || [],
    [PolicyCoverageKey.ExtendedReplacementCost]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.ExtendedReplacementCost)?.deductible_value || '',
    [PolicyCoverageKey.ReplacementCostOnContents]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.ReplacementCostOnContents)?.limit_value || '',
    [PolicyCoverageKey.WindHail]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.WindHail)?.deductible_value || '',
    [PolicyCoverageKey.Hurricane]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Hurricane)?.deductible_value || '',
    [PolicyCoverageKey.WaterBackup]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.WaterBackup)?.limit_value || '',
    [PolicyCoverageKey.Flood]: coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Flood)?.limit_value || '',
    [PolicyCoverageKey.Earthquake]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Earthquake)?.limit_value || '',
    [PolicyCoverageKey.ServiceLine]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.ServiceLine)?.limit_value || '',
    [PolicyCoverageKey.EquipmentBreakdown]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.EquipmentBreakdown)?.limit_value || '',
    [PolicyCoverageKey.Foundation]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Foundation)?.limit_value || '',
    [PolicyCoverageKey.FunctionalReplacementCost]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.FunctionalReplacementCost)?.limit_value || '',
    agent_bridge_url: quote?.attributes.binding_url || ''
  };
};

const buildCoverages = ({
  dwelling,
  other_structures,
  personal_property,
  loss_of_use,
  personal_liability,
  deductible,
  medical_payments,
  included_coverages,
  excluded_coverages,
  ...values
}: ReturnType<typeof buildInitialValues>) => {
  const dwellingNode =
    dwelling || deductible
      ? {
          key: PolicyCoverageKey.Dwelling,
          limit_value: dwelling,
          deductible_value: deductible,
          limit_description: moneyFormatter(dwelling, true),
          deductible_description: typedMoneyFormatter(
            Number(deductible) <= 100 ? DeductibleType.Percentage : DeductibleType.Flat,
            deductible,
            true
          ),
          limit_type: LimitType.MoneyLimit,
          deductible_type: Number(deductible) <= 100 ? DeductibleType.Percentage : DeductibleType.Flat
        }
      : undefined;
  const otherStructuresNode = other_structures
    ? {
        key: PolicyCoverageKey.OtherStructures,
        limit_value: other_structures,
        deductible_value: 0,
        limit_description: moneyFormatter(other_structures, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const personalPropertyNode = personal_liability
    ? {
        key: PolicyCoverageKey.PersonalProperty,
        limit_value: personal_property,
        deductible_value: 0,
        limit_description: moneyFormatter(personal_property, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const lossOfUseNode = loss_of_use
    ? {
        key: PolicyCoverageKey.LossOfUse,
        limit_value: loss_of_use,
        deductible_value: 0,
        limit_description:
          values.lossOfUseLimitType === LimitType.MoneyLimit ? moneyFormatter(loss_of_use, true) : `${loss_of_use} mo`,
        limit_type: values.lossOfUseLimitType,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const personalLiability = personal_liability
    ? {
        key: PolicyCoverageKey.PersonalLiability,
        limit_value: personal_liability,
        deductible_value: 0,
        limit_description: moneyFormatter(personal_liability, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const medicalPayments = medical_payments
    ? {
        key: PolicyCoverageKey.MedicalPayments,
        limit_value: medical_payments,
        deductible_value: 0,
        limit_description: moneyFormatter(medical_payments, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;

  const includedCoverages = included_coverages.map(key => {
    const value = Number(values[key as keyof typeof values] || 0);

    const getDeductibleDescription = (value: number) => {
      if (value && value <= 100) {
        return `${value}%`;
      } else if (value > 100) {
        return moneyFormatter(value, true);
      }
      return 'Included';
    };

    return {
      key,
      limit_value: value,
      deductible_value: value,
      limit_description: value ? moneyFormatter(value, true) : 'Included',
      limit_type: value ? LimitType.MoneyLimit : LimitType.OtherLimit,
      deductible_description: getDeductibleDescription(value),
      deductible_type: value <= 100 ? DeductibleType.Percentage : DeductibleType.Flat
    };
  });

  const excludedCoverages = excluded_coverages.map(key => ({
    key,
    limit_value: 0,
    limit_type: LimitType.OtherLimit,
    deductible_value: 0,
    deductible_type: DeductibleType.Flat,
    limit_description: 'Not included',
    deductible_description: 'Not included'
  }));

  return [
    dwellingNode,
    otherStructuresNode,
    personalPropertyNode,
    lossOfUseNode,
    personalLiability,
    medicalPayments,
    ...includedCoverages,
    ...excludedCoverages
  ].filter(Boolean);
};

const HomeQuoteForm = ({
  policyType,
  setOpenQuoteEditor,
  quote,
  asset,
  setSelectedQuoteKey
}: {
  policyType: PolicyType;
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote?: IQuote;
  asset: IHome;
  setSelectedQuoteKey: React.Dispatch<React.SetStateAction<string>>;
  selectedQuoteKey: string | undefined;
}) => {
  const queryClient = useQueryClient();
  const { personGid, leadGid } = useGuidedSellingExperienceContext();
  const { data: carriers, isPending: isPendingCarriers } = useCarriers({
    policy_type: policyType,
    appointed: true,
    state: asset.address.state
  });

  const carrierOptions = React.useMemo(() => {
    return (carriers || []).map(({ key, name }) => ({ key, value: name }));
  }, [carriers]);

  const { mutateAsync: createQuote } = useCreateQuote();

  return (
    <Container
      border
      borderColor={colors.azure50}
      boxShadow
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 400px;
      `}
      backgroundColor={colors.ghostWhite}
      p={spacings.px12}
    >
      <BaseForm
        pt={spacings.px0}
        pr={spacings.px0}
        pb={spacings.px0}
        pl={spacings.px0}
        initialValues={buildInitialValues(quote)}
        onSubmit={values => {
          const coverages = buildCoverages(values);

          createQuote({
            personGid: personGid!,
            data: {
              premium: values.premium,
              source: QuoteSource.AU,
              policy_type: policyType,
              carrier_key: values.carrier_key,
              engagement_gid: leadGid!,
              coverages,
              assets: [{ gid: asset.gid, coverages }],
              payment_options: [
                {
                  downpayment: 0,
                  subsequent_payment: Number(values.premium) / 12,
                  term_months: 12,
                  full_term_premium: Number(values.premium)
                }
              ],
              agent_bridge_url: values.agent_bridge_url
            }
          }).then(data =>
            queryClient.invalidateQueries({ queryKey: [AGGREGATED_QUOTES_QUERY_KEY, personGid] }).then(() => {
              setSelectedQuoteKey(data.quote.gid);
            })
          );

          setOpenQuoteEditor(false);
        }}
        validationSchema={yup.object().shape({
          carrier_key: requiredField,
          premium: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          agent_bridge_url: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .test('is-url', 'must be a valid URL', value => {
              try {
                new URL(value);
                return true;
              } catch (error) {
                return false;
              }
            }),
          deductible: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.Dwelling]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.OtherStructures]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.PersonalProperty]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.LossOfUse]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.PersonalLiability]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.MedicalPayments]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.Hurricane]: yup.number().min(1, 'must be greater than zero'),
          [PolicyCoverageKey.WindHail]: yup.number().min(1, 'must be greater than zero'),
          [PolicyCoverageKey.ExtendedReplacementCost]: yup
            .number()
            .min(1, 'must be greater than zero')
            .max(100, 'must be less than 100')
        })}
        customControls
        renderForm={({ values, setFieldValue }) => {
          return (
            <FlexBox columnDirection gap={spacings.px12}>
              <FlexBox justifySpaceBetween>
                <Text bold>Add insurance details</Text>
                <IconButton
                  icon={ButtonIcons.Close}
                  color={colors.black}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                />
              </FlexBox>
              <FlexBox gap={spacings.px4} columnDirection>
                <SelectField
                  name="carrier_key"
                  id="carrier_key"
                  label="Carrier"
                  options={carrierOptions}
                  inline
                  ordered
                  isLoading={isPendingCarriers}
                  customCss={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                  required
                  menuMinWidth="180px"
                />
                <InputField
                  prefixIcon={<LinkSharpIcon />}
                  name="agent_bridge_url"
                  id="agent_bridge_url"
                  label="Carrier portal URL"
                  required
                  inline
                />
                <NumericField
                  name="premium"
                  id="premium"
                  label="Premium(full period)"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  required
                  decimalScale={2}
                />
                <NumericField
                  name="deductible"
                  id="deductible"
                  label="Deductible"
                  inline
                  valueIsNumber
                  prefixIcon={
                    values.deductible && Number(values.deductible) < 100 ? <PercentageIcon /> : <DollarIcon />
                  }
                  decimalScale={2}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.Dwelling}
                  id={PolicyCoverageKey.Dwelling}
                  label="Dwelling"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.OtherStructures}
                  id={PolicyCoverageKey.OtherStructures}
                  label="Other structures"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.PersonalProperty}
                  id={PolicyCoverageKey.PersonalProperty}
                  label="Personal property"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.LossOfUse}
                  id={PolicyCoverageKey.LossOfUse}
                  label="Loss of use"
                  inline
                  valueIsNumber
                  decimalScale={2}
                  required
                  customPrefix={
                    <Container
                      customCss={css`
                        width: 62px;
                      `}
                    >
                      <Select
                        onChange={e => {
                          setFieldValue('lossOfUseLimitType', e);
                        }}
                        value={values.lossOfUseLimitType}
                        isPrefix
                        options={[
                          { key: LimitType.MoneyLimit, value: '$' },
                          { key: LimitType.MonthlyLimit, value: 'mo' }
                        ]}
                        menuMinWidth="60px"
                        customComponents={{ DropdownIndicator: SmallDropdownIndicator as unknown as React.ReactNode }}
                        customStyles={{ valueContainer: (base: any) => ({ ...base, padding: '2px' }) }}
                      />
                    </Container>
                  }
                />
                <NumericField
                  name={PolicyCoverageKey.PersonalLiability}
                  id={PolicyCoverageKey.PersonalLiability}
                  label="Personal liability"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.MedicalPayments}
                  id={PolicyCoverageKey.MedicalPayments}
                  label="Medical payments"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  decimalScale={2}
                  required
                />
                <MultiSelectField
                  name="included_coverages"
                  id="included_coverages"
                  label="Coverages included"
                  options={buildIncludedCoverages({ excludedValues: values.excluded_coverages })}
                  inline
                  customCss={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                />
                {values.included_coverages.includes(PolicyCoverageKey.ExtendedReplacementCost) && (
                  <NumericField
                    name={PolicyCoverageKey.ExtendedReplacementCost}
                    id={PolicyCoverageKey.ExtendedReplacementCost}
                    label="Extended replacement"
                    inline
                    valueIsNumber
                    prefixIcon={<PercentageIcon />}
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.WindHail) && (
                  <NumericField
                    name={PolicyCoverageKey.WindHail}
                    id={PolicyCoverageKey.WindHail}
                    label="Wind/Hail"
                    inline
                    valueIsNumber
                    prefixIcon={
                      values[PolicyCoverageKey.WindHail] && Number(values[PolicyCoverageKey.WindHail]) < 100 ? (
                        <PercentageIcon />
                      ) : (
                        <DollarIcon />
                      )
                    }
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.Hurricane) && (
                  <NumericField
                    name={PolicyCoverageKey.Hurricane}
                    id={PolicyCoverageKey.Hurricane}
                    label="Hurricane"
                    inline
                    valueIsNumber
                    prefixIcon={
                      values[PolicyCoverageKey.Hurricane] && Number(values[PolicyCoverageKey.Hurricane]) < 100 ? (
                        <PercentageIcon />
                      ) : (
                        <DollarIcon />
                      )
                    }
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.WaterBackup) && (
                  <NumericField
                    name={PolicyCoverageKey.WaterBackup}
                    id={PolicyCoverageKey.WaterBackup}
                    label="Water backup"
                    inline
                    valueIsNumber
                    prefixIcon={<DollarIcon />}
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.Earthquake) && (
                  <NumericField
                    name={PolicyCoverageKey.Earthquake}
                    id={PolicyCoverageKey.Earthquake}
                    label="Earthquake"
                    inline
                    valueIsNumber
                    prefixIcon={<DollarIcon />}
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.Flood) && (
                  <NumericField
                    name={PolicyCoverageKey.Flood}
                    id={PolicyCoverageKey.Flood}
                    label="Flood"
                    inline
                    valueIsNumber
                    prefixIcon={<DollarIcon />}
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.ServiceLine) && (
                  <NumericField
                    name={PolicyCoverageKey.ServiceLine}
                    id={PolicyCoverageKey.ServiceLine}
                    label="Service line"
                    inline
                    valueIsNumber
                    prefixIcon={<DollarIcon />}
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.EquipmentBreakdown) && (
                  <NumericField
                    name={PolicyCoverageKey.EquipmentBreakdown}
                    id={PolicyCoverageKey.EquipmentBreakdown}
                    label="Equipment breakdown"
                    inline
                    valueIsNumber
                    prefixIcon={<DollarIcon />}
                    decimalScale={2}
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.Foundation) && (
                  <NumericField
                    name={PolicyCoverageKey.Foundation}
                    id={PolicyCoverageKey.Foundation}
                    label="Foundation coverage"
                    inline
                    valueIsNumber
                    prefixIcon={<DollarIcon />}
                    decimalScale={2}
                  />
                )}
                <MultiSelectField
                  name="excluded_coverages"
                  id="excluded_coverages"
                  label="Coverages excluded"
                  options={buildExcludedCoverages({ includedValues: values.included_coverages })}
                  inline
                  customCss={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                />
              </FlexBox>
              <FlexBox gap={spacings.px4} justifyRight>
                <Button
                  type="button"
                  size={ButtonSize.Small}
                  variant={ButtonVariant.SecondaryDanger}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" size={ButtonSize.Small}>
                  Save
                </Button>
              </FlexBox>
            </FlexBox>
          );
        }}
      />
    </Container>
  );
};

export default HomeQuoteForm;
