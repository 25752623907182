import { IDocument } from './IDocument';

export enum DocumentDeliveryStatus {
  Created = 'created',
  MissedLoan = 'missed_loan',
  MissedLoanNumber = 'missed_loan_number',
  MissedFaxNumber = 'missed_fax_number',
  ReadyForDelivery = 'ready_for_delivery',
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed'
}

export enum DocumentDeliveryChannel {
  Fax = 'fax',
  Email = 'email',
  SFTP = 'sftp'
}

export enum DeliverableDocumentStatus {
  Attached = 'attached',
  MissedDocument = 'missed_document',
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed'
}

export enum RecipientType {
  Partner = 'partner',
  Customer = 'customer',
  Carrier = 'carrier'
}

export interface IDeliverableDocument {
  document: IDocument | null;
  document_type: string;
  status: DeliverableDocumentStatus;
}

export interface IDocumentDelivery {
  status: DocumentDeliveryStatus;
  channel: DocumentDeliveryChannel;
  created_at: string;
  deliverable_documents: IDeliverableDocument[];
  document_types: string[];
  recipient_type: RecipientType;
  updated_at: string;
}
