/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { CheckCircleIcon, ErrorIcon, WarningIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../components/core/PolicyTypeIcon';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IMaticPolicy } from '../../../interfaces';
import {
  DeliverableDocumentStatus,
  DocumentDeliveryChannel,
  DocumentDeliveryStatus,
  IDeliverableDocument,
  IDocumentDelivery,
  RecipientType
} from '../../../interfaces/IDocumentDelivery';
import { findPolicyType, PolicyType } from '../../../interfaces/IPolicyType';
import { generateAutoDescription, IVehicle } from '../../../interfaces/IVehicle';
import { usePolicyDeliveries } from '../../../queries/people/person_policies/usePolicyDeliveries';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import PolicySummaryCard from '../_components/PolicySummaryCard';
import Document from './Document';
import PrimaryLoan from './PrimaryLoan';

interface IExtendedDocument extends IDeliverableDocument {
  channel: DocumentDeliveryChannel;
  recipient_type: RecipientType;
  delivery_status: DocumentDeliveryStatus;
}

const PolicyDocumentsStatusTag = ({ deliveries }: { deliveries: IDocumentDelivery[] }) => {
  const completed =
    deliveries.length < 0 ||
    deliveries.every(delivery =>
      delivery.deliverable_documents.every(doc => doc.status === DeliverableDocumentStatus.Completed)
    );

  if (completed) {
    return (
      <Tag
        transparent
        singleLine
        backgroundColor={colors.statusGreen}
        label={
          <FlexBox alignItemsCenter>
            <CheckCircleIcon color={colors.statusGreen} />
            <Text type="tiny" ml={spacings.px4}>
              All tasks completed
            </Text>
          </FlexBox>
        }
      />
    );
  }

  const failed = deliveries.some(delivery =>
    delivery.deliverable_documents.some(doc => doc.status === DeliverableDocumentStatus.Failed)
  );

  if (failed) {
    return (
      <Tag
        transparent
        singleLine
        backgroundColor={colors.statusRed}
        label={
          <FlexBox alignItemsCenter>
            <ErrorIcon color={colors.statusRed} />
            <Text type="tiny" ml={spacings.px4}>
              Failed to send
            </Text>
          </FlexBox>
        }
      />
    );
  }

  return (
    <Tag
      transparent
      singleLine
      backgroundColor={colors.statusOrange}
      label={
        <FlexBox alignItemsCenter>
          <WarningIcon color={colors.statusOrange} />
          <Text type="tiny" ml={spacings.px4}>
            Documents delivery pending
          </Text>
        </FlexBox>
      }
    />
  );
};

const PolicyDetails = ({ policy }: { policy: IMaticPolicy }) => {
  const { personGid } = useGuidedSellingExperienceContext();
  const { data: deliveries } = usePolicyDeliveries({ personGid, policyId: policy.id });

  const documents = Object.entries(
    (deliveries || [])
      .flatMap(item =>
        item.deliverable_documents.map(doc => ({
          ...doc,
          channel: item.channel,
          recipient_type: item.recipient_type,
          delivery_status: item.status
        }))
      )
      .reduce(
        (groups, doc) => {
          (groups[doc.document_type] ||= []).push(doc);
          return groups;
        },
        {} as Record<string, IExtendedDocument[]>
      )
  ).map(([document_type, docs]) => ({
    document_type,
    document: (docs as IExtendedDocument[]).find(doc => doc.document)?.document || null,
    channels: (docs as IExtendedDocument[]).map(({ channel, status, recipient_type, delivery_status }) => ({
      channel,
      document_status: status,
      recipient_type,
      delivery_status
    }))
  }));

  return (
    <FlexBox justifySpaceBetween gap={spacings.px40}>
      <FlexBox
        border
        roundBorder
        columnDirection
        fitParentWidth
        css={css`
          min-width: 550px;
        `}
      >
        <FlexBox borderBottom p={spacings.px24} gap={spacings.px8}>
          <Container
            border
            roundBorder
            p={spacings.px4}
            css={css`
              height: fit-content;
            `}
          >
            <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
              <PolicyTypeIcon policyType={policy.policy_type} color={colors.grey60} height={24} width={24} />
            </Container>
          </Container>
          <FlexBox justifySpaceBetween gap={spacings.px12} fitParentWidth>
            <FlexBox columnDirection>
              <Paragraph bold type="large">
                {findPolicyType(policy.policy_type)?.name} insurance
              </Paragraph>
              <Text>
                {policy.policy_type === PolicyType.Auto
                  ? generateAutoDescription(policy.assets as IVehicle[])
                  : policy.assets?.[0]?.address.full}
              </Text>
            </FlexBox>
            <FlexBox alignItemsCenter justifyCenter>
              <PolicyDocumentsStatusTag deliveries={deliveries || []} />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        {documents.length > 0 ? (
          <FlexBox p={spacings.px24} columnDirection>
            <Text bold>Documents to deliver</Text>
            {documents.map(doc => (
              <Document
                key={doc.document_type}
                doc={doc.document}
                documentType={doc.document_type}
                channels={doc.channels}
                policy={policy}
              />
            ))}
          </FlexBox>
        ) : (
          <FlexBox alignItemsCenter justifyCenter p={spacings.px24}>
            <Text italic color={colors.grey60}>
              No information to display
            </Text>
          </FlexBox>
        )}
        <PrimaryLoan personGid={personGid!} asset={policy.assets?.[0]} policy={policy} />
      </FlexBox>
      <PolicySummaryCard policy={policy} />
    </FlexBox>
  );
};

export default PolicyDetails;
