import { skipToken, useMutation, useQuery } from '@tanstack/react-query';

import { IDocumentDelivery } from '../../../interfaces/IDocumentDelivery';
import api from '../../../services/api';
export const POLICY_DELIVERIES_QUERY_KEY = 'policyDeliveries';

const createOrUpdatePolicyDelivery = ({
  personGid,
  policyId
}: {
  personGid: string;
  policyId: number;
}): Promise<{ deliveries: IDocumentDelivery[] }> =>
  api.post(`/api/frontend/people/${personGid}/policies/${policyId}/deliveries`);

const getPolicyDeliveries = ({
  personGid,
  policyId
}: {
  personGid: string;
  policyId: number;
}): Promise<{ deliveries: IDocumentDelivery[] }> =>
  api.get(`/api/frontend/people/${personGid}/policies/${policyId}/deliveries`);

export const useCreateOrUpdatePolicyDelivery = () => useMutation({ mutationFn: createOrUpdatePolicyDelivery });

export const usePolicyDeliveries = ({ personGid, policyId }: { personGid?: string; policyId?: number }) =>
  useQuery({
    queryKey: [POLICY_DELIVERIES_QUERY_KEY, personGid, policyId],
    queryFn: policyId && personGid ? () => getPolicyDeliveries({ personGid, policyId }) : skipToken,
    select: data => data.deliveries
  });
