import { ICarrier } from './ICarrier';
import { IDocument } from './IDocument';
import { IHome } from './IHome';
import { IPersonAsset } from './IPersonAsset';
import { PolicyCoverage } from './IPolicyCoverage';
import { PolicyType } from './IPolicyType';
import { IVehicle } from './IVehicle';

export enum PolicySource {
  Agent = 'agent',
  OnlineBind = 'olb',
  AMS = 'ams',
  Unknown = 'unknown',
  Partner = 'partner',
  CarrierReport = 'carrier_report'
}

export enum PolicyStatus {
  BOUND = 'bound',
  ACTIVE = 'active',
  UP_FOR_RENEWAL = 'up_for_renewal',
  NON_RENEWAL = 'non_renewal',
  NOT_TAKEN = 'not_taken',
  CANCELLED = 'cancelled',
  RENEWED = 'renewed',
  REWRITTEN = 'rewritten',
  UNKNOWN = 'unknown'
}

export const UNKNOWN_POLICY_STATUS = 'unknown';

export const POLICY_RENEWABLE_STATUSES = [
  PolicyStatus.BOUND,
  PolicyStatus.ACTIVE,
  PolicyStatus.UP_FOR_RENEWAL,
  PolicyStatus.CANCELLED,
  PolicyStatus.NON_RENEWAL,
  PolicyStatus.NOT_TAKEN,
  PolicyStatus.RENEWED
];

export enum PolicyTransactionType {
  NEW_BUSINESS = 'new_business',
  CROSS_SALE = 'cross_sale',
  RENEWAL = 'renewal',
  REWRITE = 'rewrite'
}

export enum AgencyName {
  Matic = 'matic',
  Prediction = 'prediction'
}

export enum PolicyPaymentMethod {
  Escrow = 'escrow',
  DirectBill = 'direct_bill'
}

export interface IDocumentTransaction {
  id: number;
  docusign_envelope_id: string;
  zendesk_ticket_id: number;
  docusign_envelope_url: string;
  zendesk_ticket_url: string;
}

export interface IMaticPolicy {
  id: number;
  lead_id: null | number;
  agent_id: number;
  status: PolicyStatus;
  policy_type: PolicyType;
  transaction_type: PolicyTransactionType;
  policy_number: string;
  premium: string;
  payment_method: PolicyPaymentMethod;
  sale_date: string;
  effective_date: string;
  expiration_date: string;
  cancellation_reason: null | string;
  cancellation_date: null | string;
  state: null | string;
  new_closing: boolean | null;
  carrier: ICarrier;
  deductible_type: null | string;
  deductible_value: null | string;
  primary_coverage: null | number;
  document_transaction: null | IDocumentTransaction;
  last_ams_sync_at: null | string;
  files: null | IDocument[];
  assets: null | IPersonAsset[];
  created_at: string;
  trust_name: null | string;
  llc_name: null | string;
  source: PolicySource;
  source_policy_id: null | number;
  renewed_policy_id: null | number;
  primary_loan_gid: null | string;
}

export interface IPolicy {
  gid: string;
  policy_type: PolicyType;
  status: PolicyStatus | typeof UNKNOWN_POLICY_STATUS;
  policy_number: null | string;
  premium: null | string;
  payment_method: null | PolicyPaymentMethod;
  effective_date: null | string;
  expiration_date: null | string;
  coverages: null | PolicyCoverage[];
  lender_placed_insurance_issue_date: null | string;
  created_at: string;

  assets: (IHome | IVehicle)[] | null;
  carrier: ICarrier;
  agency_name: AgencyName | null;
}

export interface IPriorPolicy extends IPolicy {
  agency_name: never;
}
