import { useQuery } from '@tanstack/react-query';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

export const PERSON_MATIC_POLICIES_QUERY_KEY = 'person_matic_policies';

interface PersonPoliciesFilters {
  policy_types?: null | string[];
  similar_policy_types?: null | boolean;
  asset_gid?: null | string;
  show_renewed_policies?: boolean;
  lead_id?: null | number;
}

const getPersonMaticPolicies = ({
  personGid,
  filters
}: {
  personGid: string;
  filters?: PersonPoliciesFilters;
}): Promise<{ policies: IMaticPolicy[] }> =>
  api.get(`/api/frontend/people/${personGid}/policies?${toQueryParams(filters)}`);

const usePersonMaticPolicies = ({
  personGid,
  filters
}: {
  personGid: string | undefined;
  filters?: PersonPoliciesFilters;
}) =>
  useQuery({
    queryKey: [personGid, PERSON_MATIC_POLICIES_QUERY_KEY, filters],
    queryFn: () => getPersonMaticPolicies({ personGid: personGid!, filters }),
    enabled: !!personGid,
    select: data => data.policies
  });

export default usePersonMaticPolicies;
