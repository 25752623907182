/** @jsxImportSource @emotion/react */
import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ChartBarIcon, WarningIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import QuestionAnswerPair from '../../../../components/UIFlow/QuestionAnswerPair';
import { IDispositionVersion, ILead, IPartner } from '../../../../interfaces';
import { DataCollectionStepsKey } from '../../../../interfaces/IDataCollection';
import { ILeadDispositions } from '../../../../interfaces/IDisposition';
import useLeadAssignees from '../../../../queries/leads/assignees/useLeadAssignees';
import useDataCollection from '../../../../queries/leads/data_collection/useDataCollection';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import Assignee from './Assignee';
import BusinessType from './BusinessType';
import Partner from './Partner';
import WhoQualified from './WhoQualified';

const LeadEngagement = ({
  lead,
  leadDispositions,
  partners,
  dispositionsVersions
}: {
  lead: ILead;
  leadDispositions: ILeadDispositions;
  partners: IPartner[];
  dispositionsVersions: IDispositionVersion[];
}) => {
  const { data: dataCollection } = useDataCollection(lead.id);

  const { data: assignees } = useLeadAssignees({
    leadId: lead.id,
    licensed: leadDispositions.current_disposition.is_license_required
  });

  const isAutoSkipped = !dataCollection?.pages.find(page => page.key === DataCollectionStepsKey.VehicleProfile)
    ?.opportunities?.[0]?.is_data_collection_enabled;

  const getDataCollectionCompleteness = () => {
    if (isAutoSkipped) {
      return (
        <Text type="small" color={colors.statusRed}>
          Auto skipped{' '}
          <WarningIcon color={colors.statusOrange} data-for="auto-skipped" data-tip="Agent skipped auto collection" />
          <Tooltip id="auto-skipped" />
        </Text>
      );
    } else if (dataCollection?.completed) {
      return <Text type="small">Fully completed</Text>;
    }
    return <Text type="small">Data not collected</Text>;
  };

  return (
    <Container>
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <ChartBarIcon />
        <Paragraph type="small" bold>
          Lead engagement
        </Paragraph>
      </FlexBox>
      <FlexBox columnDirection gap={spacings.px4} ml={spacings.px32} mt={spacings.px12} mb={spacings.px16}>
        <Container pv={isAutoSkipped ? 0 : spacings.px4}>
          <QuestionAnswerPair
            questionLabel={
              <Text color={colors.grey60} type="small">
                Profile completeness
              </Text>
            }
            answerLabel={getDataCollectionCompleteness()}
          />
        </Container>
        <BusinessType lead={lead} />
        <Partner lead={lead} partners={partners} />
        <WhoQualified
          leadDispositions={leadDispositions}
          dispositionsVersions={dispositionsVersions}
          assignees={assignees}
          lead={lead}
        />
        <Assignee
          leadDispositions={leadDispositions}
          dispositionsVersions={dispositionsVersions}
          assignees={assignees}
          lead={lead}
        />
      </FlexBox>
    </Container>
  );
};

export default LeadEngagement;
