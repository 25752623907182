import { css, SerializedStyles } from '@emotion/react';

import colors from '../../../../theme/colors';
import { defaultFontCss, defaultFontValues } from '../../../../theme/typography';
import { borderRadius, sizes } from '../../../../theme/variables';

export const input = (
  isInvalid: boolean,
  isDisabled?: boolean,
  hasPrefix = false,
  clearable = false,
  inline = false
): SerializedStyles => css`
  background-color: ${colors.white};
  border-radius: ${borderRadius}px;
  box-sizing: border-box;
  color: ${colors.black};
  font-family: ${defaultFontValues.fontFamily};
  font-size: ${defaultFontValues.fontSize};
  height: ${sizes.formsHeight}px;
  outline: none;
  padding-left: ${hasPrefix ? 32 : sizes.inputPaddingHorizontal}px;
  padding-right: ${hasPrefix ? 20 : sizes.inputPaddingHorizontal}px;
  width: 100%;
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease,
    color 0.2s ease;
  position: relative;
  z-index: 1;
  font-variant: lining-nums;
  appearance: none;
  ${inline
    ? css`
        border: 1px solid transparent;
        &:hover {
          background-color: ${colors.grey5};
          border: 1px solid ${colors.grey5};
        }
        &:focus {
          background-color: ${colors.white};
          border: 1px solid ${colors.azure50};
        }
      `
    : css`
        border: 1px solid ${colors.black};
      `}

  ${isInvalid &&
  css`
    &,
    &:hover,
    &:focus {
      color: ${colors.statusRed};
      border-color: ${colors.statusRed};
      border-width: 1px;
    }
  `};
  ${isDisabled &&
  css`
    background-color: ${colors.grey10};
    cursor: not-allowed;
  `}
  &:focus + .input-focus-helper {
    border-color: ${colors.azure50};
  }
  ${clearable &&
  css`
    &,
    &:hover,
    &:focus {
      padding-right: 30px;
    }
  `};
`;

export const focusHelper = css`
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  border-radius: 7px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
`;

export const copyBlockStyle = css`
  background-color: ${colors.azure50};
  &:hover {
    background-color: ${colors.denim};
  }
  width: ${sizes.formsHeight}px;
  height: ${sizes.formsHeight}px;
  position: absolute;
  border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
  right: 0;
  top: 0;
  z-index: 2;
`;

export const copyIconStyle = css`
  margin: 8px;
  z-index: 3;
  cursor: pointer;
`;

export const labelStyle = (required?: boolean): SerializedStyles => css`
  ${defaultFontCss};
  text-transform: none;
  margin-left: 8px;
  ${required ? `&::after { content: '*'; color: ${colors.statusRed}; }` : ''}
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
