import moment from 'moment';

import { IMaticPolicy, IOpportunity, IVehicle } from '../../../../interfaces';
import { DocumentOwnerType, IDocument } from '../../../../interfaces/IDocument';
import {
  isInsurableInterestRealProperty,
  isInsurableInterestVehicle,
  isNonLifeAncillaryPolicyType
} from '../../../../interfaces/IPolicyType';
import { generateAutoDescription } from '../../../../interfaces/IVehicle';
import { dateFormatter } from '../../../../utils/formatter';

export const getOwnerType = ({
  fileOwner,
  opportunities,
  policies
}: {
  fileOwner: string | undefined;
  opportunities: IOpportunity[] | undefined;
  policies: IMaticPolicy[] | undefined;
}) => {
  if (opportunities?.find(opportunity => opportunity.id === Number(fileOwner))) {
    return DocumentOwnerType.Opportunity;
  } else if (policies?.find(policy => policy.id === Number(fileOwner))) {
    return DocumentOwnerType.Policy;
  } else if (fileOwner) {
    return DocumentOwnerType.Lead;
  }
  return undefined;
};

export const getPolicyLabel = ({
  ownerType,
  ownerId,
  policies
}: {
  ownerType?: DocumentOwnerType;
  ownerId?: number | null;
  policies: IMaticPolicy[] | undefined;
}) => {
  if (ownerType === DocumentOwnerType.Policy) {
    const policy = policies?.find(policy => policy.id === ownerId);
    const relevantPolicy = moment().diff(moment(policy?.expiration_date)) > 0 ? 'Policy' : 'Most relevant policy';

    return policy
      ? `${relevantPolicy} ${dateFormatter(policy.effective_date)}-${dateFormatter(policy.expiration_date)}`
      : undefined;
  }
};

export const getOpportunityLabel = ({
  ownerType,
  ownerId,
  opportunities
}: {
  ownerType?: DocumentOwnerType;
  ownerId?: number | null;
  opportunities: IOpportunity[] | undefined;
}) => {
  if (ownerType === DocumentOwnerType.Opportunity) {
    const opportunity = opportunities?.find(opportunity => opportunity.id === ownerId);
    return opportunity?.title;
  }
};

const isPolicyRelatedToOpportunity = ({
  policy,
  opportunity
}: {
  policy: IMaticPolicy | undefined;
  opportunity: IOpportunity | undefined;
}) => {
  return !!(
    policy &&
    opportunity &&
    policy.policy_type === opportunity.policy_type &&
    policy.assets?.[0]?.gid === opportunity.assets?.[0]?.gid
  );
};

const findOpportunityRelatedToPolicy = ({
  policy,
  opportunities
}: {
  policy: IMaticPolicy | undefined;
  opportunities: IOpportunity[] | undefined;
}) => {
  return (opportunities || []).find(
    opportunity =>
      opportunity.policy_type === policy?.policy_type && policy.assets?.[0]?.gid === opportunity.assets?.[0]?.gid
  );
};

export const getGroupDescription = ({
  documents,
  opportunities
}: {
  documents: IDocument[];
  opportunities: IOpportunity[] | undefined;
}) => {
  const opportunityDocument = documents.find(document => document.owner_type === DocumentOwnerType.Opportunity);
  const opportunity = (opportunities || []).find(opportunity => opportunity.id === opportunityDocument?.owner_id);
  const descriptionForVehicle =
    opportunity &&
    isInsurableInterestVehicle(opportunity.policy_type) &&
    generateAutoDescription(opportunity.assets as IVehicle[]);

  const descriptionForHome =
    opportunity &&
    isInsurableInterestRealProperty(opportunity.policy_type) &&
    opportunity.assets?.[0]?.description_without_icon;

  return descriptionForVehicle || descriptionForHome || '';
};

export const groupAndSortDocumentsByOpportunities = ({
  documents,
  opportunities,
  policies
}: {
  documents: IDocument[];
  opportunities: IOpportunity[] | undefined;
  policies: IMaticPolicy[] | undefined;
}) => {
  const policyDocuments = documents.filter(doc => doc.owner_type === DocumentOwnerType.Policy);
  const otherDocuments = documents.filter(doc => doc.owner_type === DocumentOwnerType.Lead || !doc.owner_type);

  const sortedDocuments = documents.sort(a => {
    const policyA = (policies || []).find(policy => policy.id === a.owner_id);
    const opportunityA = (opportunities || []).find(opportunity => opportunity.id === a.owner_id);
    if (policyA || (opportunityA && !isNonLifeAncillaryPolicyType({ policy_type: opportunityA.policy_type }))) {
      return -1;
    } else if (opportunityA && isNonLifeAncillaryPolicyType({ policy_type: opportunityA.policy_type })) {
      return 1;
    } else {
      return 0;
    }
  });

  const documentsGroupedByOpportunity = sortedDocuments.reduce(
    (acc, doc) => {
      const ownerId = doc.owner_id;
      const opportunity = opportunities?.find(o => o.id === ownerId);
      const opportunityLabel = opportunity?.title;

      if (opportunityLabel && doc.owner_type === DocumentOwnerType.Opportunity) {
        acc[opportunityLabel] = acc[opportunityLabel] || [];

        policyDocuments?.forEach((policyDoc, index) => {
          if (isPolicyRelatedToOpportunity({ policy: policies?.find(p => p.id === policyDoc.owner_id), opportunity })) {
            acc[opportunityLabel]!.unshift(policyDoc);
            policyDocuments.splice(index, 1);
          }
        });

        acc[opportunityLabel]!.push(doc);
      } else {
        policyDocuments?.forEach((policyDoc, index) => {
          const opportunity = findOpportunityRelatedToPolicy({
            policy: policies?.find(p => p.id === policyDoc.owner_id),
            opportunities
          });
          if (
            opportunity &&
            isPolicyRelatedToOpportunity({ policy: policies?.find(p => p.id === policyDoc.owner_id), opportunity })
          ) {
            acc[opportunity.title] = acc[opportunity.title] || [];
            acc[opportunity.title]!.unshift(policyDoc);
            policyDocuments.splice(index, 1);
          }
        });
      }

      return acc;
    },
    {} as Record<string, IDocument[]>
  );

  return { otherDocuments, documentsGroupedByOpportunity };
};

export const MAX_FILE_SIZE = 30 * 1024 * 1000; // 30 MB

export const maxSizeValidator = (file: File) => {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'file-too-large',
      message: `File ${file.name} is larger than 30 MB.`
    };
  }
  return null;
};

export const acceptedFileTypes = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'text/csv': [],
  'text/plain': [],
  'text/xml': [],
  'audio/mpeg': [],
  'video/mp4': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/xml': []
};
