/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../components/core/FlexBox';
import FormModal from '../../../../components/core/FormModal';
import { SelectField } from '../../../../components/core/forms/fields';
import Text from '../../../../components/core/Text';
import QuestionAnswerPair from '../../../../components/UIFlow/QuestionAnswerPair';
import { useToggle } from '../../../../hooks';
import { IDispositionVersion, ILead, IUser } from '../../../../interfaces';
import { DispositionType, ILeadDispositions } from '../../../../interfaces/IDisposition';
import useEditDispositionAssignee from '../../../../queries/leads/dispositions/useEditDispositionAssignee';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import DispositionsVersionModal from './_components/DispositionsVersionsModal';
import UserRoleTag from './_components/UserRoleTag';

const WhoQualified = ({
  leadDispositions,
  dispositionsVersions,
  assignees,
  lead
}: {
  leadDispositions: ILeadDispositions;
  dispositionsVersions: IDispositionVersion[];
  assignees: IUser[] | undefined;
  lead: ILead;
}) => {
  const queryClient = useQueryClient();
  const [openedWhoQualified, toggleOpenedWhoQualifiedModal] = useToggle(false);
  const [openedWhoQualifiedVersionsModal, toggleOpenedWhoQualifiedVersionsModal] = useToggle(false);
  const { mutateAsync: editDispositionAssignee } = useEditDispositionAssignee();

  const qualifiedDisposition = leadDispositions.dispositions.find(
    d => d.disposition_type === DispositionType.Qualified
  );
  const whoQualified = qualifiedDisposition?.assignee;

  const isWhoQualifiedUpdated = dispositionsVersions.find(
    d =>
      d.event === 'update' &&
      d.disposition_type === DispositionType.Qualified &&
      d.changes?.[0]?.content.includes('assignee_id')
  );

  const isFieldEditable = authInfo.features.update_who_qualified && whoQualified;

  return (
    <FlexBox pv={isFieldEditable ? 0 : spacings.px4}>
      <QuestionAnswerPair
        questionLabel={
          <Text color={colors.grey60} type="small">
            Who qualified
          </Text>
        }
        answerLabel={
          <FlexBox gap={spacings.px4} fitParentWidth justifySpaceBetween>
            <FlexBox
              gap={spacings.px4}
              customCss={css`
                width: calc(100% - 32px);
              `}
              alignItemsCenter
            >
              <Text type="small" singleLine title={whoQualified ? whoQualified.name : ''}>
                {whoQualified?.name || '—'}
              </Text>
              <UserRoleTag processedWithRole={qualifiedDisposition?.assignee_role?.label} />
              {isWhoQualifiedUpdated && (
                <Button
                  onClick={() => {
                    analytics.track('Lead operations viewed', {
                      item: 'who_qualified',
                      lead_gid: lead.gid
                    });
                    toggleOpenedWhoQualifiedVersionsModal();
                  }}
                  variant={ButtonVariant.PlainText}
                >
                  <Text underline dotted color={colors.statusOrange} type="small">
                    Updated
                  </Text>
                </Button>
              )}
            </FlexBox>
            {isFieldEditable && (
              <IconButton icon={ButtonIcons.Edit} color={colors.black} onClick={toggleOpenedWhoQualifiedModal} />
            )}
          </FlexBox>
        }
      />

      {openedWhoQualified && assignees && whoQualified && (
        <FormModal
          initialValues={{ assignee: whoQualified.id }}
          title="You're updating who qualified"
          confirmText="Update"
          confirmHandler={values => {
            analytics.track('Lead operations updated', { item: 'who_qualified', lead_gid: lead.gid });

            editDispositionAssignee({
              leadId: lead.id,
              dispositionType: DispositionType.Qualified,
              assigneeId: values.assignee!
            }).then(() => {
              toggleOpenedWhoQualifiedModal();
              queryClient.invalidateQueries();
            });
          }}
          cancelHandler={toggleOpenedWhoQualifiedModal}
          validationSchema={yup.object().shape({ assignee: yup.string().required('Please select an assignee') })}
          renderForm={() => (
            <SelectField
              options={assignees.map(assignee => ({ key: assignee.id, value: assignee.name }))}
              id="assignee"
              name="assignee"
              label="Assignee name"
              required
              customCss={css`
                max-width: 320px;
              `}
            />
          )}
        />
      )}
      {openedWhoQualifiedVersionsModal && (
        <DispositionsVersionModal
          dispositionsVersions={dispositionsVersions}
          cancelHandler={toggleOpenedWhoQualifiedVersionsModal}
        />
      )}
    </FlexBox>
  );
};

export default WhoQualified;
