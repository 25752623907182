/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Container from '../../../../../components/core/Container';
import Copy from '../../../../../components/core/Copy';
import DescriptionList from '../../../../../components/core/DescriptionList';
import FlexBox from '../../../../../components/core/FlexBox';
import { WarningIcon } from '../../../../../components/core/icons';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import { IQuote } from '../../../../../interfaces';
import { CarrierKey } from '../../../../../interfaces/ICarrier';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { getBridgingUnavailableMessage, isBridgingAvailable } from '../_helpers';

interface ExternalLinkModalProps {
  quote: IQuote;
  cancelBtnHandler: () => void;
}

const ExternalLinkModal = ({ quote, cancelBtnHandler }: ExternalLinkModalProps) => {
  const {
    attributes: { binding_url, carrier, notice, external_id }
  } = quote;

  const allowBridge = isBridgingAvailable(quote);
  const bridgingUnavailableMessage = getBridgingUnavailableMessage(quote);

  return (
    <Modal
      title={binding_url ? `Go to ${carrier.name} website` : `Check ${carrier.name} instructions`}
      size={ModalSize.large}
      cancelHandler={cancelBtnHandler}
      returnFocusAfterClose={false}
      confirmHandler={
        binding_url && allowBridge
          ? () => {
              window.open(binding_url, '_blank');
              cancelBtnHandler();
            }
          : undefined
      }
      confirmText="Go to website"
    >
      {notice && (
        <DescriptionList
          term={
            <FlexBox alignItemsCenter gap={spacings.px4}>
              <Text bold>Notice</Text>
              <WarningIcon color={colors.statusOrange} />
            </FlexBox>
          }
          details={<Text>{notice}</Text>}
        />
      )}
      {external_id && (
        <DescriptionList
          term={<Text bold>External ID</Text>}
          details={
            <Copy value={external_id}>
              <Text className="fs-mask">{external_id}</Text>
            </Copy>
          }
        />
      )}
      {binding_url && allowBridge && (
        <DescriptionList
          term={<Text bold>External URL</Text>}
          details={
            <Copy value={binding_url}>
              <Text
                customCss={css`
                  max-width: 600px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                `}
              >
                {binding_url}
              </Text>
            </Copy>
          }
        />
      )}
      {bridgingUnavailableMessage && (
        <Paragraph bold mt={spacings.px12} ml={spacings.px4}>
          {bridgingUnavailableMessage}
        </Paragraph>
      )}
      {carrier.key === CarrierKey.Universal && (
        <FlexBox ml={spacings.px4} mt={spacings.px16} columnDirection gap={spacings.px16}>
          <Container>
            <Paragraph bold>Roof Year</Paragraph>
            <Text>If roof year was manually entered or changed, proof of roof update is required</Text>
          </Container>
          <Container>
            <Paragraph bold>Prior Insurance</Paragraph>
            <Text>If YES is selected for Prior Insurance, proof of insurance is required</Text>
          </Container>
          <Container>
            <Paragraph bold>Loss History</Paragraph>
            <Text>
              If a loss is reported by carrier or customer, you must manually enter in the loss using &quot;add prior
              loss&quot; button
            </Text>
          </Container>
        </FlexBox>
      )}
    </Modal>
  );
};

export default ExternalLinkModal;
