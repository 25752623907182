/** @jsxImportSource @emotion/react */
import debounce from 'debounce-promise';
import * as React from 'react';
import AsyncReactSelect, { AsyncProps } from 'react-select/async';

import colors from '../../../../theme/colors';
import { InputSize } from '../../../../theme/variables';
import { ClearIndicator, Control, DropdownIndicator, IndicatorSeparator, Input, Menu, styles } from '../Select';

export interface AsyncSelectProps extends AsyncProps<any, false, any> {
  name?: string;
  placeholder?: string;
  inline?: boolean;
  inputSize?: InputSize;
  disabled?: boolean;
  onChange: (args: any) => void;
  asyncAction: (args: any) => Promise<any> | void;
  optionsNormalizer: (args: any) => any;
  hasError?: boolean;
}

const AsyncSelect = ({
  name = '',
  placeholder,
  disabled = false,
  onChange,
  // @ts-expect-error - wrong type in react-select
  value,
  inputSize = InputSize.Medium,
  asyncAction,
  optionsNormalizer,
  ...props
}: AsyncSelectProps): JSX.Element => {
  const [selectOptions, setSelectOptions] = React.useState<{ label: string; value: any }[]>([]);
  const onValueChange = ({ value }: { value: any }) => {
    if (name) {
      onChange({ target: { name, value } });
    } else {
      onChange(value);
    }
  };

  const loadOptions = debounce(async term => {
    if (!term) {
      return;
    }

    const data = await asyncAction(term);
    const normalizedOptions = optionsNormalizer(data);
    setSelectOptions(normalizedOptions);
    return normalizedOptions;
  }, 500);

  return (
    <AsyncReactSelect
      {...props}
      styles={styles(!!props.inline, inputSize)}
      placeholder={placeholder || (props.inline ? '—' : 'Start typing to search')}
      value={selectOptions.find(option => option.value === value)}
      loadOptions={loadOptions}
      onChange={onValueChange}
      components={{
        Control,
        ClearIndicator,
        IndicatorSeparator,
        DropdownIndicator,
        Input,
        Menu
      }}
      isDisabled={disabled}
      theme={(theme: any) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.azure50,
          primary25: colors.grey10
        }
      })}
    />
  );
};

export default AsyncSelect;
