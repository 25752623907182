import { spacings } from '../../theme/variables';
import Text, { TextProps } from '../core/Text';

interface EmptyAnswerProps {
  textType?: TextProps['type'];
}

const EmptyAnswer = ({ textType }: EmptyAnswerProps) => (
  // Spacing is needed to compensate for <Copy/> component internal padding
  <Text type={textType} pl={spacings.px4}>
    —
  </Text>
);

export default EmptyAnswer;
