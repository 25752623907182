import { PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';

export const PROPOSAL_IFRAME_ID = 'proposal_iframe';

export const EDIT_DISABLED_TOOLTIP_ID = 'quote-edit-disabled-tooltip';

export const reloadIframe = () => {
  const iframe = document.getElementById(PROPOSAL_IFRAME_ID) as HTMLIFrameElement;
  if (iframe) {
    iframe.src += '';
  }
};

export const HOME_COVERAGES_OPTIONS = [
  PolicyCoverageKey.ExtendedReplacementCost,
  PolicyCoverageKey.ReplacementCostOnContents,
  PolicyCoverageKey.WindHail,
  PolicyCoverageKey.Hurricane,
  PolicyCoverageKey.WaterBackup,
  PolicyCoverageKey.Flood,
  PolicyCoverageKey.Earthquake,
  PolicyCoverageKey.ServiceLine,
  PolicyCoverageKey.EquipmentBreakdown,
  PolicyCoverageKey.Foundation,
  PolicyCoverageKey.FunctionalReplacementCost
] as const;

const COVERAGE_LABELS = {
  [PolicyCoverageKey.ExtendedReplacementCost]: 'Extended Replacement Cost',
  [PolicyCoverageKey.ReplacementCostOnContents]: 'Replacement Cost on Contents',
  [PolicyCoverageKey.WindHail]: 'Wind/Hail',
  [PolicyCoverageKey.Hurricane]: 'Hurricane',
  [PolicyCoverageKey.WaterBackup]: 'Water Backup',
  [PolicyCoverageKey.Flood]: 'Flood',
  [PolicyCoverageKey.Earthquake]: 'Earthquake',
  [PolicyCoverageKey.ServiceLine]: 'Service Line',
  [PolicyCoverageKey.EquipmentBreakdown]: 'Equipment Breakdown',
  [PolicyCoverageKey.Foundation]: 'Foundation',
  [PolicyCoverageKey.FunctionalReplacementCost]: 'Functional Replacement Cost'
} as const;

export const buildIncludedCoverages = ({ excludedValues }: { excludedValues: PolicyCoverageKey[] }) =>
  HOME_COVERAGES_OPTIONS.map(coverage => ({
    key: coverage,
    label: COVERAGE_LABELS[coverage],
    isDisabled: excludedValues.includes(coverage)
  }));

export const buildExcludedCoverages = ({ includedValues }: { includedValues: PolicyCoverageKey[] }) =>
  HOME_COVERAGES_OPTIONS.map(coverage => ({
    key: coverage,
    label: COVERAGE_LABELS[coverage],
    isDisabled: includedValues.includes(coverage)
  }));
