import { skipToken, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { IOpportunity } from '../../../interfaces/IOpportunity';
import { InsurableInterest, Requirement } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

export const PERSON_OPPORTUNITIES_QUERY_KEY = 'person_opportunities';

const getPersonOpportunities = ({
  personGid,
  leadGid,
  candidateGid,
  requirement,
  insurableInterest
}: {
  personGid: string | undefined;
  leadGid?: string;
  candidateGid?: string;
  requirement?: Requirement;
  insurableInterest?: InsurableInterest;
}): Promise<{ opportunities: IOpportunity[] }> =>
  api.get(
    `/api/frontend/people/${personGid}/opportunities/v2?${toQueryParams({
      lead_gid: leadGid,
      candidate_gid: candidateGid,
      requirement,
      insurable_interest: insurableInterest
    })}`
  );

const usePersonOpportunities = ({
  personGid,
  leadGid,
  candidateGid,
  requirement,
  insurableInterest
}: {
  personGid: string | undefined;
  leadGid?: string;
  candidateGid?: string;
  requirement?: Requirement;
  insurableInterest?: InsurableInterest;
}) =>
  useQuery({
    queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY, personGid, leadGid, candidateGid, requirement, insurableInterest],
    queryFn: personGid
      ? () => getPersonOpportunities({ personGid, leadGid, candidateGid, requirement, insurableInterest })
      : skipToken,
    select: data => data.opportunities
  });

export const useSuspensePersonOpportunities = ({
  personGid,
  leadGid,
  candidateGid,
  requirement,
  insurableInterest
}: {
  personGid: string;
  leadGid?: string;
  candidateGid?: string;
  requirement?: Requirement;
  insurableInterest?: InsurableInterest;
}) =>
  useSuspenseQuery({
    queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY, personGid, leadGid, candidateGid, requirement, insurableInterest],
    queryFn: () => getPersonOpportunities({ personGid, leadGid, candidateGid, requirement, insurableInterest }),

    select: data => data.opportunities
  });

export default usePersonOpportunities;
