/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../components/core/FlexBox';
import FormModal from '../../../../components/core/FormModal';
import { SelectField } from '../../../../components/core/forms/fields';
import Modal, { ModalSize } from '../../../../components/core/Modal';
import Text from '../../../../components/core/Text';
import QuestionAnswerPair from '../../../../components/UIFlow/QuestionAnswerPair';
import { Translations } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { ILead } from '../../../../interfaces';
import { IPaperTrailVersion } from '../../../../interfaces/IPaperTrailVersion';
import { BusinessTypes } from '../../../../interfaces/ISourceDimensions';
import useLeadBusinessTypeVersions from '../../../../queries/leads/business_type/useLeadBusinessTypeVersions';
import { updateBusinessType } from '../../../../queries/leads/business_type/useUpdateBusinessType';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { dateTimeFormatter, SCOUTING_REPORT_DATE_TIME_FORMAT } from '../../../../utils/formatter';
import UserRoleTag from './_components/UserRoleTag';
import Version from './_components/Version';

const getChangesValueName = (change: string | undefined) => {
  return change ? change.split(' ')[1] : '';
};

const getBusinessTypeNameChanges = ({
  changes,
  initial
}: {
  changes: IPaperTrailVersion['changes'];
  initial: boolean;
}): string => {
  const initialBusinessTypeName =
    Translations.businessType(getChangesValueName(changes?.[0]?.content) as BusinessTypes) || '';
  const updatedBusinessTypeName = Translations.businessType(
    getChangesValueName(changes?.[1]?.content) as BusinessTypes
  );

  return initial ? initialBusinessTypeName : `${initialBusinessTypeName} → ${updatedBusinessTypeName}`;
};

const BusinessType = ({ lead }: { lead: ILead }) => {
  const queryClient = useQueryClient();
  const { business_type: businessType } = lead.source_dimensions;

  const [openedBusinessTypeModal, toggleOpenedBusinessTypeModal] = useToggle(false);
  const [openedBusinessTypeVersionsModal, toggleOpenedBusinessTypeVersionsModal] = useToggle(false);

  const { data: businessTypeVersions } = useLeadBusinessTypeVersions({ leadGid: lead.gid });
  const isBusinessTypeUpdated = businessTypeVersions?.find(version => version.event === 'update');
  const displayEditButton = authInfo.features.update_business_type;

  return (
    <FlexBox pv={displayEditButton ? 0 : spacings.px4}>
      <QuestionAnswerPair
        questionLabel={
          <Text color={colors.grey60} type="small">
            Business type
          </Text>
        }
        answerLabel={
          <FlexBox gap={spacings.px4} fitParentWidth justifySpaceBetween>
            <FlexBox
              gap={spacings.px4}
              customCss={css`
                width: calc(100% - 32px);
              `}
              alignItemsCenter
            >
              <Text type="small" singleLine title={Translations.businessType(businessType)}>
                {Translations.businessType(businessType)}
              </Text>
              {isBusinessTypeUpdated && (
                <Button
                  onClick={() => {
                    analytics.track('Lead operations viewed', {
                      item: 'business_type',
                      lead_gid: lead.gid
                    });
                    toggleOpenedBusinessTypeVersionsModal();
                  }}
                  variant={ButtonVariant.PlainText}
                >
                  <Text underline dotted color={colors.statusOrange} type="small">
                    Updated
                  </Text>
                </Button>
              )}
            </FlexBox>
            {displayEditButton && (
              <IconButton icon={ButtonIcons.Edit} color={colors.black} onClick={toggleOpenedBusinessTypeModal} />
            )}
          </FlexBox>
        }
      />

      {openedBusinessTypeModal && (
        <FormModal
          initialValues={{ business_type: businessType }}
          title="You're updating business type"
          confirmText="Update"
          confirmHandler={values => {
            analytics.track('Lead operations updated', { item: 'business_type', lead_gid: lead.gid });

            updateBusinessType({ leadId: lead.id, businessType: values.business_type! }).then(() => {
              toggleOpenedBusinessTypeModal();
              queryClient.invalidateQueries();
            });
          }}
          cancelHandler={toggleOpenedBusinessTypeModal}
          validationSchema={yup
            .object()
            .shape({ business_type: yup.string().required('Please select a business type') })}
          renderForm={() => (
            <SelectField
              options={Translations.businessTypeOptions}
              id="business_type"
              name="business_type"
              label="Business type"
              required
              customCss={css`
                max-width: 320px;
              `}
            />
          )}
        />
      )}
      {openedBusinessTypeVersionsModal && (
        <Modal
          title="Log of changes made with the business type field"
          showCancelButton={false}
          cancelHandler={toggleOpenedBusinessTypeVersionsModal}
          size={ModalSize.large}
        >
          <FlexBox gap={spacings.px8} columnDirection p={spacings.px12}>
            {businessTypeVersions?.map(version => {
              return (
                <Version created={version.event === 'create'} key={version.id} columnsTemplate="34% 44% 19%">
                  <Text
                    color={colors.grey60}
                    type="small"
                    singleLine
                    title={getBusinessTypeNameChanges({
                      changes: version.changes,
                      initial: version.event === 'create'
                    })}
                  >
                    {getBusinessTypeNameChanges({ changes: version.changes, initial: version.event === 'create' })}
                  </Text>
                  <FlexBox alignItemsCenter gap={spacings.px8} title={`by ${version.author_name || 'System'}`}>
                    <Text color={colors.grey60} type="small" singleLine>
                      by {version.author_name || 'System'}
                    </Text>
                    <UserRoleTag processedBy={version.processed_by} processedWithRole={version.processed_with_role} />
                  </FlexBox>
                  <Text color={colors.grey60} type="small" singleLine>
                    {dateTimeFormatter(version.created_at, SCOUTING_REPORT_DATE_TIME_FORMAT)}
                  </Text>
                </Version>
              );
            })}
          </FlexBox>
        </Modal>
      )}
    </FlexBox>
  );
};

export default BusinessType;
