/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import FormModal from '../../../components/core/FormModal';
import { RadioGroupField } from '../../../components/core/forms/fields';
import { BuildingsIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { useToggle } from '../../../hooks';
import { IMaticPolicy, IPersonAsset } from '../../../interfaces';
import { servicerOrLender } from '../../../interfaces/ILender';
import { findPolicyType, PolicyType } from '../../../interfaces/IPolicyType';
import { generateAutoDescription, IVehicle } from '../../../interfaces/IVehicle';
import { PERSON_MATIC_POLICIES_QUERY_KEY } from '../../../queries/people/person_policies/usePersonMaticPolicies';
import useUpdatePolicy, { UpdatePolicyRequest } from '../../../queries/people/person_policies/useUpdatePolicy';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';

const PrimaryLoan = ({
  personGid,
  asset,
  policy
}: {
  personGid: string;
  asset?: IPersonAsset;
  policy: IMaticPolicy;
}) => {
  const queryClient = useQueryClient();
  const [editPrimaryLoan, toggleEditPrimaryLoan] = useToggle(false);
  const { data: loans } = usePersonLoans({ personGid, assetGid: asset?.gid });
  const { mutateAsync: updatePolicy, isPending } = useUpdatePolicy();
  const primaryLoan = loans?.find(loan => loan.gid === policy.primary_loan_gid) || loans?.[0];
  const company = servicerOrLender({ servicer: primaryLoan?.servicer, lender: primaryLoan?.lender });

  return (
    <FlexBox columnDirection gap={spacings.px12} ph={spacings.px24} pb={spacings.px24}>
      <FlexBox gap={spacings.px8}>
        <Text bold>Review primary loan</Text>
        {loans && loans.length > 1 && (
          <Button variant={ButtonVariant.Text} onClick={toggleEditPrimaryLoan}>
            Select primary
          </Button>
        )}
      </FlexBox>
      {primaryLoan ? (
        <FlexBox justifySpaceBetween gap={spacings.px12} alignItemsBaseline border p={spacings.px12} roundBorder>
          <FlexBox
            gap={spacings.px12}
            alignItemsCenter
            css={css`
              max-width: 55%;
            `}
          >
            <Container border roundBorder p={spacings.px4}>
              <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
                <BuildingsIcon color={colors.grey60} />
              </Container>
            </Container>

            <FlexBox columnDirection fitParentWidth>
              <FlexBox alignItemsCenter gap={spacings.px8}>
                <Text singleLine>{company?.business_name}</Text>
              </FlexBox>
              {company?.mortgagee_address?.full && (
                <Paragraph type="small" color={colors.grey60} singleLine>
                  {company.mortgagee_address.full}
                </Paragraph>
              )}
            </FlexBox>
          </FlexBox>
          {primaryLoan.loan_number && (
            <>
              <Text color={colors.grey60} type="small">
                Loan #<Copy value={primaryLoan.loan_number}>{primaryLoan.loan_number}</Copy>
              </Text>
            </>
          )}
        </FlexBox>
      ) : (
        <FlexBox alignItemsCenter justifyCenter p={spacings.px24}>
          <Text italic color={colors.grey60}>
            No information to display
          </Text>
        </FlexBox>
      )}
      {editPrimaryLoan && (
        <FormModal
          title="Select primary loan"
          confirmText="Update"
          cancelText="Cancel"
          cancelHandler={toggleEditPrimaryLoan}
          confirmHandler={values => {
            const { id, ...rest } = policy;
            updatePolicy({
              person_gid: personGid,
              policy_id: id,
              carrier_id: policy.carrier.id,
              ...rest,
              primary_loan_gid: values.primary_loan
            } as unknown as UpdatePolicyRequest).then(() => {
              queryClient.invalidateQueries({ queryKey: [personGid, PERSON_MATIC_POLICIES_QUERY_KEY] });
              toggleEditPrimaryLoan();
            });
          }}
          confirmationInProgress={isPending}
          initialValues={{ primary_loan: primaryLoan?.gid || '' }}
          renderForm={() => {
            return (
              <FlexBox columnDirection gap={spacings.px24}>
                <RadioGroupField
                  columnDirection
                  label={() => (
                    <Paragraph bold>
                      For {findPolicyType(policy.policy_type)?.name} policy{' '}
                      {policy.policy_type === PolicyType.Auto
                        ? generateAutoDescription(policy.assets as IVehicle[])
                        : policy.assets?.[0]?.address.full}
                    </Paragraph>
                  )}
                  required
                  id="primary_loan"
                  name="primary_loan"
                  options={(loans || []).map(loan => ({
                    id: loan.gid,
                    label: servicerOrLender({ servicer: loan.servicer, lender: loan.lender })?.business_name || '',
                    description: (
                      <Text type="small" color={colors.grey60}>
                        Loan # {loan.loan_number}
                      </Text>
                    ),
                    value: loan.gid
                  }))}
                />
              </FlexBox>
            );
          }}
        />
      )}
    </FlexBox>
  );
};

export default PrimaryLoan;
