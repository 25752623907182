/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { IPerson } from '../../../interfaces';
import { spacings } from '../../../theme/variables';
import AssetsSummary from './AssetsSummary';
import LoansSummary from './LoansSummary';
import PeopleSummary from './PeopleSummary';

const Summary = ({ person }: { person: IPerson }) => {
  return (
    <FlexBox
      columnDirection
      css={css`
        position: relative;
        top: 160px;
      `}
      gap={spacings.px24}
      p={spacings.px24}
    >
      <PeopleSummary person={person} />
      <AssetsSummary person={person} />
      <LoansSummary person={person} />
    </FlexBox>
  );
};

export default Summary;
