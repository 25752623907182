/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../core/FlexBox';

interface IQuestionAnswerPair {
  questionLabel: JSX.Element | string;
  answerLabel: JSX.Element | string;
}

const QuestionAnswerPair = ({ questionLabel, answerLabel }: IQuestionAnswerPair) => {
  return (
    <FlexBox
      fitParentWidth
      alignItemsCenter
      customCss={css`
        gap: 4px;
      `}
    >
      <FlexBox
        customCss={css`
          min-width: 138px;
          width: 138px;
        `}
      >
        {questionLabel}
      </FlexBox>
      <FlexBox
        alignItemsCenter
        fitParentWidth
        customCss={css`
          gap: 2px;
          min-width: 0;
        `}
      >
        {answerLabel}
      </FlexBox>
    </FlexBox>
  );
};

export default QuestionAnswerPair;
