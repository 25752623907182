import { rejectEmpty, slice, values } from '../../utils/object';

export const addItemsKey = (array: any) => array && array.map((item: any) => ({ key: crypto.randomUUID(), ...item }));

export const autoName = (auto: any) => {
  const normalized = rejectEmpty(slice(auto, 'year', 'make', 'model', 'submodel'));

  return values(normalized).join(' ');
};

export const autosEqual = (lAuto: any, rAuto: any) => {
  const lNormalizedAuto = rejectEmpty(lAuto);
  const rNormalizedAuto = rejectEmpty(rAuto);

  return (
    lNormalizedAuto.make === rNormalizedAuto.make &&
    lNormalizedAuto.model === rNormalizedAuto.model &&
    lNormalizedAuto.submodel === rNormalizedAuto.submodel &&
    lNormalizedAuto.year === rNormalizedAuto.year &&
    lNormalizedAuto.vin === rNormalizedAuto.vin
  );
};

const normalizeNamePart = (namePart: string) => namePart.toUpperCase().trim();

export const driversEqual = (
  lDriver: { first_name: string; last_name: string },
  rDriver: { first_name: string; last_name: string }
) =>
  normalizeNamePart(lDriver.first_name) === normalizeNamePart(rDriver.first_name) &&
  normalizeNamePart(lDriver.last_name) === normalizeNamePart(rDriver.last_name);

export const driverName = (driver: any) => {
  const normalized = rejectEmpty(slice(driver, 'first_name', 'middle_name', 'last_name'));

  return values(normalized).join(' ');
};

export const joinAddress = (address: any) => {
  if (address) {
    const normalized = rejectEmpty(slice(address, 'line1', 'line2', 'city', 'state', 'zip'));

    return values(normalized).join(', ');
  }
};
