/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { components } from 'react-select';

import { ArrowUpIcon } from '../../../components/core/icons';
import colors from '../../../theme/colors';

export const SmallDropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ArrowUpIcon
      className="select-dropdown-indicator"
      color={colors.black}
      css={css`
        width: 12px;
        height: 12px;
        transform: rotate(${props.selectProps.menuIsOpen ? 0 : 180}deg);
        transition:
          transform 0.3s ease,
          visibility 0.2s ease,
          opacity 0.2s ease;
      `}
    />
  </components.DropdownIndicator>
);
