import { create } from 'zustand';

const useVerticalTabsCallback = create(() => ({
  onTabClose: () => {
    useVerticalTabsCallback.setState({ selectedIndex: -1 });
  },
  selectedIndex: -1,
  setSelectedIndex: (index: number) => {
    useVerticalTabsCallback.setState({ selectedIndex: index });
  }
}));

export default useVerticalTabsCallback;
