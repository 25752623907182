/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import DottedTag from '../../../components/core/DottedTag';
import FlexBox from '../../../components/core/FlexBox';
import { ErrorIcon, HourglassIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { usePopper } from '../../../hooks';
import { IDocument } from '../../../interfaces';
import { DeliverableDocumentStatus, DocumentDeliveryStatus } from '../../../interfaces/IDocumentDelivery';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { IChannel } from './Document';

const ChannelTag = ({ status }: { status: DocumentDeliveryStatus }) => {
  switch (status) {
    case DocumentDeliveryStatus.ReadyForDelivery:
    case DocumentDeliveryStatus.Created:
      return <DottedTag text="Not sent yet" color={colors.grey60} icon={<HourglassIcon color={colors.grey60} />} />;
    case DocumentDeliveryStatus.MissedFaxNumber:
      return <DottedTag text="Missed fax number" color={colors.statusOrange} />;
    case DocumentDeliveryStatus.MissedLoan:
      return <DottedTag text="Missed loan" color={colors.statusOrange} />;
    case DocumentDeliveryStatus.MissedLoanNumber:
      return <DottedTag text="Missed loan number" color={colors.statusOrange} />;
    case DocumentDeliveryStatus.Pending:
      return <DottedTag text="Pending" color={colors.statusOrange} />;
    case DocumentDeliveryStatus.Completed:
      return <DottedTag text="Sent" color={colors.statusGreen} />;
    case DocumentDeliveryStatus.Failed:
      return <DottedTag text="Failed to send" color={colors.statusRed} />;
    default:
      return <DottedTag text="Unknown" color={colors.grey60} />;
  }
};

const DocumentStatusTag = ({ channels, document }: { channels: IChannel[]; document: IDocument | null }) => {
  if (!document) {
    return <DottedTag text="Missed document" color={colors.grey30} />;
  }

  const pending = channels.every(channel => channel.document_status === DeliverableDocumentStatus.Pending);

  if (pending) {
    return <DottedTag text={`Pending(${channels.length})`} color={colors.statusOrange} />;
  }

  const partlyPendingDocs = channels.filter(channel => channel.document_status === DeliverableDocumentStatus.Pending);

  if (partlyPendingDocs.length > 0) {
    return <DottedTag text={`Pending(${partlyPendingDocs.length}/${channels.length})`} color={colors.statusOrange} />;
  }

  const sent = channels.every(
    channel =>
      channel.document_status === DeliverableDocumentStatus.Completed &&
      channel.delivery_status === DocumentDeliveryStatus.Completed
  );

  if (sent) {
    return <DottedTag text={`Sent(${channels.length})`} color={colors.statusGreen} />;
  }

  const partlySentDocs = channels.filter(
    channel =>
      channel.document_status === DeliverableDocumentStatus.Completed &&
      channel.delivery_status === DocumentDeliveryStatus.Completed
  );

  if (partlySentDocs.length > 0) {
    return <DottedTag text={`Sent(${partlySentDocs.length}/${channels.length})`} color={colors.statusOrange} />;
  }

  const failed = channels.filter(channel => channel.delivery_status === DocumentDeliveryStatus.Failed);

  if (failed.length > 0 && failed.length < channels.length) {
    return (
      <DottedTag
        text={`Failed to send(${failed.length}/${channels.length})`}
        color={colors.grey60}
        icon={<ErrorIcon color={colors.statusRed} />}
      />
    );
  }

  if (failed.length === channels.length) {
    return (
      <DottedTag
        text={`Failed to send(${channels.length})`}
        color={colors.statusRed}
        icon={<ErrorIcon color={colors.statusRed} />}
      />
    );
  }

  return (
    <DottedTag
      text={`Not sent yet(${channels.length})`}
      color={colors.grey60}
      icon={<HourglassIcon color={colors.grey60} />}
    />
  );
};

const DocumentStatus = ({ channels, document }: { channels: IChannel[]; document: IDocument | null }) => {
  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    style: { padding: '12px', zIndex: 999 }
  });

  return (
    <FlexBox>
      <FlexBox
        alignItemsCenter
        gap={spacings.px4}
        onMouseEnter={triggerPopper}
        onMouseLeave={() => setAnchorEl(null)}
        customCss={css`
          border-radius: 8px;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            background-color: ${colors.grey5};
          }
        `}
        backgroundColor={anchorEl ? colors.grey5 : 'transparent'}
        ph={spacings.px6}
        pv={spacings.px6}
      >
        <DocumentStatusTag channels={channels} document={document} />
      </FlexBox>

      <div ref={elementRef}>
        <PopperComponent {...popperProps}>
          <FlexBox
            ph={spacings.px24}
            pv={spacings.px12}
            gap={spacings.px8}
            roundBorder
            border
            boxShadow
            backgroundColor={colors.white}
            columnDirection
            customCss={css`
              min-width: 300px;
            `}
          >
            <FlexBox columnDirection gap={spacings.px20}>
              {channels.map(channel => (
                <FlexBox key={channel.channel + channel.recipient_type} justifySpaceBetween gap={spacings.px12}>
                  <Text
                    singleLine
                    css={css`
                      width: 100px;
                    `}
                  >
                    To {channel.recipient_type}
                  </Text>
                  <Text
                    color={colors.grey60}
                    singleLine
                    css={css`
                      width: 70px;
                    `}
                  >
                    by AMP
                  </Text>
                  <Text
                    singleLine
                    css={css`
                      width: 125px;
                    `}
                  >
                    <ChannelTag status={channel.delivery_status} />
                  </Text>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>
        </PopperComponent>
      </div>
    </FlexBox>
  );
};

export default DocumentStatus;
