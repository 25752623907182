/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { CloseIcon } from '../../../../components/core/icons';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import useVerticalTabsCallback from '../../_hooks/useVerticalTabsCallback';
import { VERTICAL_TAB_HEADER_Z_INDEX } from './helpers';

const headerContentHeight = 52;
const shadowHeight = 10;

const VerticalTabHeader = ({ children, showClose = true }: { children: React.ReactNode; showClose?: boolean }) => {
  const { onTabClose } = useVerticalTabsCallback();

  return (
    <div
      css={css`
        background: ${colors.white};
        height: ${headerContentHeight + shadowHeight}px;
        position: sticky;
        top: -${shadowHeight}px;
        z-index: 4;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
      `}
    >
      <FlexBox
        justifySpaceBetween
        alignItemsCenter
        p={spacings.px12}
        backgroundColor={colors.white}
        customCss={css`
          height: ${headerContentHeight}px;
          position: sticky;
          top: 0;
          margin-top: -${shadowHeight}px; // compensate for shadow with negative margin
          z-index: ${VERTICAL_TAB_HEADER_Z_INDEX}; // content should fall over shadow and cover
        `}
      >
        {children}
        {showClose && (
          <Container
            p={spacings.px4}
            roundBorder
            css={css`
              &:hover {
                background-color: ${colors.grey5};
                cursor: pointer;
              }
            `}
            onClick={() => onTabClose()}
          >
            <CloseIcon />
          </Container>
        )}
      </FlexBox>
    </div>
  );
};

export default VerticalTabHeader;
