import { useSuspenseQuery } from '@tanstack/react-query';

import { CoverageMapping } from '../../interfaces/ICoverageMapping';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

interface CoveragesMappingsDashboard {
  coverages_mappings: CoverageMapping[];
  count: number;
  pages: number;
}

export interface CoveragesMappingsQueryParams {
  carrier_id: string | undefined;
  kind: 'home' | 'auto';
  ivans_code: string | undefined;
  ivans_description: string | undefined;
  page: number | undefined;
  per_page: number | undefined;
}

interface CoverageMappingUpdateParams {
  coverage_mapping_id: number;
  mapped_code: string | undefined;
  mapped_description: string | undefined;
  mapped_category: string | undefined;
}

export const updateCoverageMapping = (
  params: CoverageMappingUpdateParams
): Promise<{ coverage_mapping: CoverageMapping }> =>
  api.put(`/api/frontend/coverages_mappings/${params.coverage_mapping_id}`, {
    body: params
  });

const getCoveragesMappings = (params: CoveragesMappingsQueryParams): Promise<CoveragesMappingsDashboard> =>
  api.get(`/api/frontend/coverages_mappings?${toQueryParams(params)}`);

const useSuspenseCoveragesMappings = (params: CoveragesMappingsQueryParams) =>
  useSuspenseQuery({
    queryKey: ['coverages_mappings', params],
    queryFn: () =>
      params.kind ? getCoveragesMappings(params) : Promise.resolve({ coverages_mappings: [], count: 0, pages: 0 })
  });

export default useSuspenseCoveragesMappings;
