import { useQueryClient } from '@tanstack/react-query';
import { FieldArray } from 'formik';
import React from 'react';
import * as yup from 'yup';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import FlexBox from '../../../../../components/core/FlexBox';
import BaseForm from '../../../../../components/core/forms/BaseForm';
import {
  AddressField,
  DateInputField,
  InputField,
  PhoneField,
  SelectField
} from '../../../../../components/core/forms/fields';
import { MailIcon, UserAddIcon, UserIcon } from '../../../../../components/core/icons';
import { QUESTION_VERIFICATION_STATUS_TOOLTIP } from '../../../../../components/core/QuestionVerificationStatusIcon';
import Text from '../../../../../components/core/Text';
import { formInputLabelStyle } from '../../../../../components/UIFlow/input.styles';
import { Translations } from '../../../../../constants';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../../../constants/addressForm';
import { useConstant } from '../../../../../hooks';
import { IAddressSuggestion, ILead, IPerson, IPersonAddress, IRelatedPerson } from '../../../../../interfaces';
import DatapointKey, { DatapointsUsageKey } from '../../../../../interfaces/IDatapoint';
import { InsurableInterest } from '../../../../../interfaces/IPolicyType';
import { useSaveAnswers as useSaveAnswersByDatapointsUsage } from '../../../../../queries/answers/useAnswers';
import { useReconcileOpportunities } from '../../../../../queries/leads/data_collection/useDataCollectionOpportunities';
// eslint-disable-next-line max-len
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../../../../queries/people/person_opportunities/usePersonOpportunities';
import authInfo from '../../../../../services/authInfo';
import { InputSize, LabelSize, spacings } from '../../../../../theme/variables';
import { createSmartyStreetsMapping, renameKeys } from '../../../../../utils/object';
import {
  emailField,
  minDOBField,
  phoneField,
  REQUIRED_MESSAGE,
  requiredAddressSchema,
  requiredField
} from '../../../../../utils/yupRules';
import { useCustomerProfileInfoTabInitialValues } from './_hooks';

interface ICustomerProfileForm {
  person: IPerson;
  relatedPeople: IRelatedPerson[];
  personAddresses: IPersonAddress[];
  lead: ILead | undefined;
  isDataVerifiedByCustomer: boolean;

  onClose: () => void;
  onSubmit: () => Promise<unknown>;
}

const CustomerProfileForm = ({
  person,
  relatedPeople,
  personAddresses,
  lead,
  isDataVerifiedByCustomer,
  onClose,
  onSubmit
}: ICustomerProfileForm) => {
  const { personInitialValues, relatedPeopleInitialValues, answersWithVerificationStatus } =
    useCustomerProfileInfoTabInitialValues({
      person,
      relatedPeople,
      isDataVerifiedByCustomer
    });
  const todayDate = useConstant(() => new Date());

  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();

  const { mutateAsync: saveAnswers } = useSaveAnswersByDatapointsUsage();

  const queryClient = useQueryClient();

  const validationSchema = yup.object({
    person: yup.object().shape({
      first_name: requiredField,
      last_name: requiredField,
      date_of_birth: minDOBField().required(REQUIRED_MESSAGE),
      email: emailField.required(REQUIRED_MESSAGE),
      phone: phoneField({ loose: authInfo.features.loose_phone_validation }).required(REQUIRED_MESSAGE),
      phone_secondary: phoneField({ loose: authInfo.features.loose_phone_validation }),
      phone_business: phoneField({ loose: authInfo.features.loose_phone_validation }),
      marital_status: requiredField,
      mailing_address: requiredAddressSchema
    }),
    related_people: yup.array().of(
      yup.object().shape({
        first_name: requiredField,
        last_name: requiredField,
        date_of_birth: minDOBField().required(REQUIRED_MESSAGE),
        email: emailField,
        phone: phoneField({ loose: authInfo.features.loose_phone_validation }),
        marital_status: requiredField,
        relation_kind: requiredField
      })
    )
  });

  return (
    <BaseForm
      pt={spacings.px0}
      pr={spacings.px0}
      pb={spacings.px0}
      pl={spacings.px0}
      customControls
      validationSchema={validationSchema}
      initialValues={{
        ...personInitialValues,
        related_people: relatedPeopleInitialValues
      }}
      onSubmit={async ({ person, related_people }) => {
        const buildPersonAnswers = (data: typeof person | Omit<(typeof related_people)[number], 'relation_kind'>) => {
          return Object.keys(data).map(key => ({
            key: `person_${key}`,
            person_gid: data.gid,
            engagement_gid: lead?.gid || null,
            value: data[key as keyof typeof data],
            asset_gid: null,
            related_person_gid: null
          }));
        };

        await Promise.all([
          saveAnswers({
            datapointsUsageKey: DatapointsUsageKey.CustomerProfileInfo,
            answers: buildPersonAnswers(person)
          }),
          ...related_people.map(relatedPerson =>
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.PersonRelationKind,
              answers: [
                {
                  key: DatapointKey.PersonRelationKind,
                  person_gid: person.gid,
                  engagement_gid: lead?.gid || null,
                  value: relatedPerson.relation_kind,
                  asset_gid: null,
                  related_person_gid: relatedPerson.gid
                }
              ]
            })
          ),
          ...related_people.map(({ relation_kind, ...rest }) =>
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.CustomerProfileInfo,
              answers: buildPersonAnswers(rest)
            })
          )
        ]);

        if (lead) {
          await reconcileOpportunities({ leadId: lead.id, insurableInterest: InsurableInterest.PersonalBelongings });
        }

        queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });

        await onSubmit();
      }}
      renderForm={({ values, submitForm, isSubmitting }) => {
        return (
          <FlexBox columnDirection border roundBorder boxShadow boxHoverShadow p={spacings.px8}>
            <FlexBox justifySpaceBetween>
              <FlexBox gap={spacings.px12} alignItemsCenter>
                <UserIcon width={spacings.px20} height={spacings.px20} />
                <Text type="small" bold ml={spacings['-px4']}>
                  Primary customer
                </Text>
              </FlexBox>

              <FlexBox gap={spacings.px8}>
                <Button
                  data-testid="cancel-panel-customer-info-form"
                  variant={ButtonVariant.Secondary}
                  size={ButtonSize.Small}
                  type="button"
                  loading={isSubmitting}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="submit-panel-customer-info-form"
                  variant={ButtonVariant.Default}
                  size={ButtonSize.Small}
                  type="submit"
                  loading={isSubmitting}
                  onClick={() => submitForm()}
                >
                  Save
                </Button>
              </FlexBox>
            </FlexBox>

            <FlexBox columnDirection pl={spacings.px24}>
              <InputField
                fsMask
                inline
                label="First name"
                id="person.first_name"
                name="person.first_name"
                testId="person.first_name"
                required
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonFirstName]?.verificationStatus
                }
              />
              <InputField
                fsMask
                inline
                label="Middle name"
                id="person.middle_name"
                name="person.middle_name"
                testId="person.middle_name"
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonMiddleName]?.verificationStatus
                }
              />
              <InputField
                fsMask
                inline
                label="Last name"
                id="person.last_name"
                name="person.last_name"
                testId="person.last_name"
                required
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonLastName]?.verificationStatus
                }
              />
              <PhoneField
                fsMask
                inline
                label="Phone"
                id="person.phone"
                name="person.phone"
                testId="person.phone"
                required
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonPhone]?.verificationStatus
                }
              />
              <PhoneField
                fsMask
                inline
                label="Second phone"
                id="person.phone_secondary"
                name="person.phone_secondary"
                testId="person.phone_secondary"
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonPhoneSecondary]?.verificationStatus
                }
              />
              <PhoneField
                fsMask
                inline
                label="Business phone"
                id="person.phone_business"
                name="person.phone_business"
                testId="person.phone_business"
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonPhoneBusiness]?.verificationStatus
                }
              />
              <InputField
                fsMask
                inline
                label="Email"
                id="person.email"
                name="person.email"
                testId="person.email"
                required
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonEmail]?.verificationStatus
                }
              />
              <DateInputField
                fsMask
                inline
                label="DOB"
                id="person.date_of_birth"
                name="person.date_of_birth"
                testId="person.date_of_birth"
                maxDate={todayDate}
                required
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonDateOfBirth]?.verificationStatus
                }
              />
              <SelectField
                inline
                label="Gender"
                id="person.gender"
                name="person.gender"
                testId="person.gender"
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                options={Translations.genderOptions}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonGender]?.verificationStatus
                }
              />
              <SelectField
                inline
                label="Marital status"
                id="person.marital_status"
                name="person.marital_status"
                testId="person.marital_status"
                required
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                options={Translations.maritalStatusOptions}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonMaritalStatus]?.verificationStatus
                }
              />
              <InputField
                inline
                fsMask
                label="License number"
                id="person.license_number"
                name="person.license_number"
                testId="person.license_number"
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonLicenseNumber]?.verificationStatus
                }
              />
              <SelectField
                inline
                label="License state"
                id="person.license_state"
                name="person.license_state"
                testId="person.license_state"
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                options={Translations.usaStates}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonLicenseState]?.verificationStatus
                }
              />
            </FlexBox>

            <FieldArray
              name="related_people"
              render={() => {
                return (
                  <>
                    {values.related_people.map((relatedPerson, index) => (
                      <FlexBox columnDirection key={relatedPerson.gid} mt={spacings.px8}>
                        <FlexBox gap={spacings.px12} alignItemsCenter>
                          <UserAddIcon width={spacings.px20} height={spacings.px20} />
                          <Text type="small" bold ml={spacings['-px4']}>
                            Co-insured
                          </Text>
                        </FlexBox>
                        <FlexBox columnDirection pl={spacings.px24} mt={spacings.px4}>
                          <InputField
                            fsMask
                            inline
                            label="First name"
                            id={`related_people.${index}.first_name`}
                            name={`related_people.${index}.first_name`}
                            testId={`related_people.${index}.first_name`}
                            required
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonFirstName]
                                ?.verificationStatus
                            }
                          />
                          <InputField
                            fsMask
                            inline
                            label="Middle name"
                            id={`related_people.${index}.middle_name`}
                            name={`related_people.${index}.middle_name`}
                            testId={`related_people.${index}.middle_name`}
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonMiddleName]
                                ?.verificationStatus
                            }
                          />
                          <InputField
                            fsMask
                            inline
                            label="Last name"
                            id={`related_people.${index}.last_name`}
                            name={`related_people.${index}.last_name`}
                            testId={`related_people.${index}.last_name`}
                            required
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonLastName]
                                ?.verificationStatus
                            }
                          />
                          <PhoneField
                            fsMask
                            inline
                            label="Phone"
                            id={`related_people.${index}.phone`}
                            name={`related_people.${index}.phone`}
                            testId={`related_people.${index}.phone`}
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonPhone]
                                ?.verificationStatus
                            }
                          />
                          <InputField
                            fsMask
                            inline
                            label="Email"
                            id={`related_people.${index}.email`}
                            name={`related_people.${index}.email`}
                            testId={`related_people.${index}.email`}
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonEmail]
                                ?.verificationStatus
                            }
                          />
                          <DateInputField
                            fsMask
                            inline
                            required
                            label="DOB"
                            id={`related_people.${index}.date_of_birth`}
                            name={`related_people.${index}.date_of_birth`}
                            testId={`related_people.${index}.date_of_birth`}
                            maxDate={todayDate}
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonDateOfBirth]
                                ?.verificationStatus
                            }
                          />
                          <SelectField
                            inline
                            label="Gender"
                            id={`related_people.${index}.gender`}
                            name={`related_people.${index}.gender`}
                            testId={`related_people.${index}.gender`}
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            options={Translations.genderOptions}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonGender]
                                ?.verificationStatus
                            }
                          />
                          <SelectField
                            inline
                            label="Relationship to customer"
                            id={`related_people.${index}.relation_kind`}
                            name={`related_people.${index}.relation_kind`}
                            testId={`related_people.${index}.kind`}
                            required
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            options={Translations.relationshipOptions}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonRelationKind]
                                ?.verificationStatus
                            }
                          />
                          <SelectField
                            inline
                            label="Marital status"
                            id={`related_people.${index}.marital_status`}
                            name={`related_people.${index}.marital_status`}
                            testId={`related_people.${index}.marital_status`}
                            required
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            options={Translations.maritalStatusOptions}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonMaritalStatus]
                                ?.verificationStatus
                            }
                          />
                          <InputField
                            inline
                            fsMask
                            label="License number"
                            id={`related_people.${index}.license_number`}
                            name={`related_people.${index}.license_number`}
                            testId={`related_people.${index}.license_number`}
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonLicenseNumber]
                                ?.verificationStatus
                            }
                          />
                          <SelectField
                            inline
                            label="License state"
                            id={`related_people.${index}.license_state`}
                            name={`related_people.${index}.license_state`}
                            testId={`related_people.${index}.license_state`}
                            labelSize={LabelSize.Small}
                            inputSize={InputSize.Medium}
                            options={Translations.usaStates}
                            customLabelCss={formInputLabelStyle}
                            verificationStatus={
                              answersWithVerificationStatus[relatedPerson.gid]?.[DatapointKey.PersonLicenseState]
                                ?.verificationStatus
                            }
                          />
                        </FlexBox>
                      </FlexBox>
                    ))}
                  </>
                );
              }}
            />

            <FlexBox gap={spacings.px4} alignItemsCenter>
              <MailIcon width={spacings.px20} height={spacings.px20} />
              <AddressField
                required
                inline
                manualEntryAllowed={false}
                key="person.mailing_address"
                label="Mailing address"
                id="person.mailing_address"
                testId="person.mailing_address"
                defaultValue={
                  renameKeys(API_TO_SMARTY_STREETS_MAPPING, values.person.mailing_address) as IAddressSuggestion
                }
                defaultOptions={
                  personAddresses.map(address =>
                    renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
                  ) as IAddressSuggestion[]
                }
                fallbackNames={createSmartyStreetsMapping('person.mailing_address.')}
                labelSize={LabelSize.Small}
                inputSize={InputSize.Medium}
                customLabelCss={formInputLabelStyle}
                verificationStatus={
                  answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonMailingAddress]?.verificationStatus
                }
              />
            </FlexBox>
            <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
          </FlexBox>
        );
      }}
    />
  );
};

export default CustomerProfileForm;
