/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import alert from '../../../../../components/core/Alert';
import Text from '../../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { isLicenseLookupAvailable } from '../../../../../queries/people/useObtainDrivingLicense';
import colors from '../../../../../theme/colors';
import { useCleanupLocalStorage } from '../../../_hooks';
import useQuotesModalView from './useModalView';

const LICENSE_LOOKUP_KEY_PREFIX = 'license-lookup-alert';

const useLicenseLookupAlert = (quotesPresence: boolean) => {
  const { person } = useGuidedSellingExperienceContext();
  const { quotesModalView } = useQuotesModalView();

  const showAlert =
    person &&
    quotesPresence &&
    !quotesModalView &&
    isLicenseLookupAvailable({
      dateOfBirth: person.date_of_birth,
      address: person.living_address,
      licenseNumber: person.license_number
    }) &&
    !localStorage.getItem(`${LICENSE_LOOKUP_KEY_PREFIX}-${person.gid}`);

  useCleanupLocalStorage(LICENSE_LOOKUP_KEY_PREFIX);

  React.useEffect(() => {
    if (showAlert) {
      alert({
        message: (
          <Text
            type="small"
            color={colors.grey60}
            block
            customCss={css`
              width: 290px;
            `}
          >
            Since you&apos;ve collected auto information and received quotes, the license number lookup feature is now
            available to you. You can access this functionality in the customer information section
          </Text>
        ),
        title: 'Enabled licensed number lookup',
        options: {
          autoClose: 10000
        }
      }).info();

      localStorage.setItem(`${LICENSE_LOOKUP_KEY_PREFIX}-${person.gid}`, Date.now().toString());
    }
  }, [showAlert, person]);
};

export default useLicenseLookupAlert;
