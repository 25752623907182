/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import { ClearIcon } from '../../../../components/core/icons';
import Checkbox from '../../../../components/core/inputs/Checkbox';
import Note from '../../../../components/core/Note';
import Paragraph, { ParagraphType } from '../../../../components/core/Paragraph';
import SideBarPopperLayout from '../../../../components/core/SideBarPopperLayout';
import { usePopper, useToggle } from '../../../../hooks';
import { IScheduledCall } from '../../../../interfaces';
import { CallStatus } from '../../../../interfaces/IScheduledCall';
import useCallNotes from '../../../../queries/scheduled_calls/useCallNotes';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import ScheduleCall from '../../../ScheduleCall';
import { DueDate } from './helpers';

const Call = ({
  call,
  completed,
  personGid,
  testId
}: {
  call: IScheduledCall;
  completed: boolean;
  personGid?: string;
  testId: string;
}): JSX.Element => {
  const [editMode, toggleEditMode] = useToggle(false);

  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps } = usePopper({
    placement: 'left',
    disablePortal: false,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      }
    ],
    style: { zIndex: 999 }
  });

  const { data: notes, isPending: isPendingCallNotes } = useCallNotes(
    anchorEl ? personGid : undefined,
    call.created_at
  );

  const canceled = call.status === CallStatus.Cancelled;

  const callTime = call.run_at;

  return (
    <FlexBox
      pv={spacings.px8}
      ph={spacings.px12}
      gap={spacings.px8}
      backgroundColor={anchorEl ? colors.grey5 : undefined}
      alignItemsFlexStart
      customCss={css`
        &:hover {
          background-color: ${colors.grey5};
          cursor: pointer;
        }
      `}
      onMouseEnter={triggerPopper}
      onMouseLeave={() => setAnchorEl(null)}
      borderBottom
      data-testid={testId}
    >
      <FlexBox alignItemsFlexStart fitParentWidth justifySpaceBetween>
        <FlexBox gap={spacings.px8}>
          <Container
            data-tip="Scheduled call is marked as 'completed' automatically after call is finished"
            data-for={`check-${call.id}`}
            p={spacings.px4}
          >
            {canceled ? (
              <Container mr={spacings.px8}>
                <ClearIcon color={colors.statusRed} />
              </Container>
            ) : (
              <Checkbox label="" id={`${call.id}`} name={`${call.id}-checkbox`} checked={completed} disabled />
            )}
            {!canceled && !completed && <Tooltip id={`check-${call.id}`} />}
          </Container>
          <FlexBox columnDirection>
            <Paragraph bold color={completed ? colors.grey60 : colors.black} crossed={completed}>
              Scheduled call
            </Paragraph>
            <DueDate dueTime={callTime} completed={completed} decoration={true} />
          </FlexBox>
        </FlexBox>
        <Container
          data-tip={`You can’t edit because it’s ${canceled ? 'canceled' : 'completed'}`}
          data-for={`edit-call-${call.id}`}
        >
          <IconButton
            icon={ButtonIcons.Edit}
            onClick={() => {
              setAnchorEl(null);
              toggleEditMode();
            }}
            focus={editMode}
            disabled={completed}
            customCss={css`
              &:hover {
                background-color: ${completed ? colors.grey5 : colors.white};
              }
            `}
          />
          {completed && <Tooltip id={`edit-call-${call.id}`} />}
        </Container>
      </FlexBox>
      <PopperComponent {...popperProps}>
        <SideBarPopperLayout
          header={
            <>
              <Paragraph type={ParagraphType.Small}>Scheduled call</Paragraph>
              <DueDate dueTime={callTime} completed={completed} decoration={false} />
            </>
          }
          firstSubHeaderTerm="Assignee"
          firstSubHeader={call.assignee?.name || 'Unknown'}
          body={
            <FlexBox gap={spacings.px8} columnDirection>
              {isPendingCallNotes ? (
                <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
                  <Heading type="h1">
                    <Skeleton />
                  </Heading>
                  <Heading type="h1">
                    <Skeleton />
                  </Heading>
                </SkeletonTheme>
              ) : (
                notes?.map(note => {
                  return (
                    <FlexBox columnDirection key={note.created_at}>
                      <Note note={note} />
                    </FlexBox>
                  );
                })
              )}
            </FlexBox>
          }
        />
      </PopperComponent>

      {editMode && (
        <div
          css={css`
            cursor: default;
          `}
        >
          <ScheduleCall
            scheduledCall={call}
            personGid={personGid!}
            cancelHandler={toggleEditMode}
            afterSubmitHandler={toggleEditMode}
          />
        </div>
      )}
    </FlexBox>
  );
};

export default Call;
